import React from "react";
import Typewriter from "typewriter-effect";

export const Type= () => {
  return (
    <Typewriter
      options={{
        strings: [
          "Market Analysts",
          "Media Consultants",
          "Market Research Associates",
          "Anyone in the marketing"
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}
