/*
  modified : Ashutosh G
  modified : 12/10/2023
  modification : Component for Contribute Modal
*/
/*
  modified : Ashutosh G
  modified : 30/10/2023
  modification : donation link changed
*/
/*
  modified : Ashutosh G
  modified : 11/12/2023
  modification : component upgrade according to every org functionality
*/
import React, { useEffect, useState, useMemo, useRef } from "react";
import { Button, InfoPanel, Heading, Text, InputField, Card, Select } from "fictoan-react";
import QRCode from "react-qr-code";
import { getItem } from '../../../lib/myStore';
import Multiselect from 'multiselect-react-dropdown';
import axios from "axios";
import CONSTANTS from "../../../lib/constants";
import NoImageFound from "../../images/Image_not_available.png"
import LoadingGif from "../../../assets/icons/tube-spinner.svg";
import { useHistory } from "react-router-dom";
import GLOBEICON from "../../../assets/icons/globe.js";
import ResponsivePagination from 'react-responsive-pagination';
import '../../../styles/pagination.css';

let modalStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "20px"
};

let loadingBoxStyle = {
  margin: "100px 0px 160px",
}

function paginateArray(dataArray, limit, pageNumber = 1) {
  const startIndex = (pageNumber - 1) * limit;
  const endIndex = startIndex + limit;

  const paginatedData = dataArray.slice(startIndex, endIndex);

  const totalPages = Math.ceil(dataArray.length / limit);
  const hasNextPage = endIndex < dataArray.length;
  const hasPrevPage = pageNumber > 1;

  return {
    paginatedData,
    totalPages,
    hasNextPage,
    hasPrevPage,
  };
}

export default function ContributeModal({isOpen, setIsOpen, toggleContributeModal}) {
  const [donationLink, setDonationLink] = useState("https://donate.stripe.com/test_00gaF55RT9yEeuA000");
  let userInfo = getItem("userinfo");
  const nonProfitRef = useRef(null);
  const [selectedCauses, setSelectedCauses] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [allCauses, setAllCauses] = useState([]);
  const [nonProfitsData, setNonPorfitData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [location, setLocation] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [response, setResponse] = useState(null);
  let history = useHistory()
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  let limit = 10;
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState(<p>Please Select Cause and loaction.</p>);

  useEffect(() => {
    getCauses();
  }, []);

  /*
    modified : Ashutosh G
    modified : 24/01/2024
    modification : message formatted based on useEffect dependencies
  */
  useEffect(() => {
    let message = null;
    if (isError) {
      message = <p style={{ textAlign: "center" }}>Something went wrong. <br/>Please try again later</p>;
    } else if (searchTerm && response && response.paginatedData.length === 0) {
      message = <p>No Data Found</p>;
      setResponse(null);
    } else if (searchTerm) {
      message = <p>Click the search button to get non-profits details.</p>;
    } else {
      message = <p>Please select cause and loaction.</p>;
    }
    setMessage(message);
  }, [searchTerm, isError, isLoading]);

  /*
    modified : Ashutosh G
    modified : 24/01/2024
    modification : scrolling to element when list is shown
  */
  useEffect(() => {
    if (nonProfitsData.length !== 0 && nonProfitRef.current) {
      nonProfitRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [nonProfitRef.current]);

  /*
    modified : Ashutosh G
    modified : 18/01/2024
    modification : Extracting data from master data when page changed
  */
  useEffect(() => {
    let searchTimeout;
    if (response && response.paginatedData && pageNumber) {
      setIsLoading(true);
      searchTimeout = setTimeout(() => {
          let filteredData = paginateArray(response.paginatedData, limit, pageNumber);
          setNonPorfitData(filteredData.paginatedData);
          setTotalPages(filteredData.totalPages);
          setIsLoading(false);
      }, 500);
    }

    return () => clearInterval(searchTimeout);
  }, [pageNumber]);

  /*
    modified : Ashutosh G
    modified : 18/01/2024
    modification : canceling previous request
  */
  useEffect(() => {
    return () => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel('Operation canceled due new request');
      }
    };
  }, [cancelTokenSource]);

  const openNewWindow = () => {
    const routeURL = donationLink;
    const newWindow = window.open(routeURL, '_blank');
    if (newWindow) {
      newWindow.focus();
    }
  };

  /*
    modified : Ashutosh G
    modified : 12/12/2023
    modification : getCauses, getNonProfits, goToDonationPage function added
  */
  async function getCauses() {
    try {
      let causeData = await axios.get(`${CONSTANTS.API_URL}/api/contribution/causes`);
      if (causeData.data.length > 0) {
        setAllCauses(prevValue => { return [{ label: "Select Cause", value: "" }, ...causeData.data] });
      }
    } catch (error) {
      console.log(error);
    }
  }

  /*
    modified : Ashutosh G
    modified : 17/01/2024
    modification : getting filtered nonProfits data from our BE
  */
  /*
    modified : Ashutosh G
    modified : 18/01/2024
    modification : storing cancelTokenSource on request to cancel it later
*/
  async function getNonProfits() {
    try {
      if (!searchTerm) {
        return;
      }

      // Cancel the previous request if it exists
      if (cancelTokenSource) {
        cancelTokenSource.cancel('Operation canceled by the user.');
      }
      /*
        modified : Ashutosh G
        modified : 24/01/2024
        modification : resetting page count
      */
      setPageNumber(1);
      setNonPorfitData([]);
      setIsLoading(true);
      setIsError(false);

      // Create a new cancel token for the current request
      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      let params = {
        searchTerm: searchTerm,
      };

      if (location) {
        params["location"] = location;
      }

      let response = await axios.get(`${CONSTANTS.API_URL}/api/nonProfits?${new URLSearchParams(params)}`, {
        cancelToken: newCancelTokenSource.token,
      });

      if (response.data.paginatedData) {
        let filteredData = paginateArray(response.data.paginatedData, limit);
        setNonPorfitData(filteredData.paginatedData);
        setTotalPages(filteredData.totalPages);
        setResponse(response.data);
      }
      setIsLoading(false);
    } catch (error) {
      if (!axios.isCancel(error)) {
        setIsLoading(false);
        setIsError(true);
      }
    }
  }

  function onSelect(selectedList, selectedItem) {
    setSelectedCauses(prevCauses => [...prevCauses, selectedItem]);
    /*
      modified : Ashutosh G
      modified : 18/12/2023
      modification : setting cause
    */
    setSearchTerm(selectedItem.value);
  }

  function onRemove(selectedList, removedItem) {
    let causesWithOutRemovedCause = selectedList.filter(item =>  item.id === removedItem.id);
    setSelectedCauses(prevCauses => [...causesWithOutRemovedCause]);
    /*
      modified : Ashutosh G
      modified : 18/12/2023
      modification : removing cause
    */
    setSearchTerm("");
  }

  function changeSearchTerm(e) {
    setSearchTerm(e.target.value);
  }

  function changeLocation(e) {
    setLocation(e.target.value);
  }

  function goToDonationPage(nonProfit) {
    let params = {
      webhook_token: CONSTANTS.everyOrg.webhook_token,
      partner_donation_id: userInfo.uuid
    };
    if (searchTerm) {
      const metaDataJSON = { causes: searchTerm }
      const base64EncodedData = btoa(JSON.stringify(metaDataJSON));
      params["partner_metadata"] = encodeURIComponent(base64EncodedData);
    }
    let donationLink = `${CONSTANTS.everyOrg.url}${nonProfit.ein}?${new URLSearchParams(params)}#donate`;
    window.open(donationLink, "_blank");
    /*
      modified : Ashutosh G
      modified : 12/12/2023
      modification : toggleContributeModal call to closed modal after redirected to contribute page
    */
    toggleContributeModal();
    /*
      modified : Ashutosh G
      modified : 14/12/2023
      modification : pushing /admin/contributions after everyOrg page invocked
    */
    history.push("/admin/contributions");
  }

  /*
    modified : Ashutosh G
    modified : 17/01/2024
    modification : used useMemo for optimization while showing cards
  */
  const renderedNonProfits = useMemo(() => {
    return nonProfitsData.map((nonProfit, index) => (
      <Card key={index} style={{ borderRadius: "5px", border: "1px solid #ffb400", marginBottom: "20px" }} >
        <img
          src={nonProfit.coverImageUrl ? nonProfit.coverImageUrl : NoImageFound}
          style={{
            width: "100%",
            height: "350px",
            borderRadius: "5px 5px 0 0",
            objectFit: "cover",
          }}
          alt={`${nonProfit.name} image here`}
        />
        <div style={{ padding: "15px", display:"flex", flexDirection: "column", gap: "15px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Heading as="h3">{nonProfit.name}</Heading>
              <Text style={{ fontWeight: "bold" }}>{nonProfit.location}</Text>
            </div>
            { nonProfit.websiteUrl && <a href={nonProfit.websiteUrl} target="_blank" rel="noreferrer noopener"><GLOBEICON width={"25px"} height={"25px"} stroke={"#ffb400"} style={{ cursor: "pointer" }}/></a> }
          </div>
          <Text>{nonProfit.description}</Text>
          {nonProfit.ein && <Button kind="primary" onClick={() => goToDonationPage(nonProfit)}>Donate</Button>}
        </div>
      </Card>
    ));
  }, [nonProfitsData]);

  return(
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px"
      }}>
          <Heading as="h3" style={{ textAlign: "center" }}>Make an Impact to Social Trends</Heading>
          <Text>
            TrendTonik is a free web service for the industry; however, we encourage you to make a donation to a social cause and help our mission to make trending human truths more accessible. New generation of market researchers and thinkers supported on our TrendTonik platform creates enormous opportunities for all of us to come together and support social impact. Did you know worldwide human interest in being charitable has gone up 3x over the past 3 years? (Google Trends)
            Make a Contribution, Make an Impact to Social Trends.
            Browse giving opportunities or search for a cause.
          </Text>

          {/*<InputField
              type="text"
              className="selectbox select"
              label="Enter Search Term"
              placeholder='Search Term'
              value={searchTerm}
              onChange={changeSearchTerm}
          />*/}

          {/*
            modified : Ashutosh G
            modified : 18/12/2023
            modification : autocomplete added for cause selection
          */}
          <div style={{ width: "100%" }}>
            <div className="numberFormatDiv">
              <Multiselect
                displayValue="name"
                selectionLimit={1}
                placeholder={searchTerm ? "" : "Select Cause"}
                // onKeyPressFn={function noRefCheck(){}}
                // onSearch={function noRefCheck(){}}
                onRemove={onRemove}
                onSelect={onSelect}
                options={allCauses.slice(1, allCauses.length)}
                selectedValues={selectedCauses}
              />
              <label>{`Select Cause`}</label>
            </div>
            {/*
              modified : Ashutosh G
              modified : 24/01/2024
              modification : label coverted to Sentence Case
            */}
            <InputField
                type="text"
                label="Search by Location (Optional)"
                placeholder='Enter Location'
                value={location}
                onChange={changeLocation}
            />
            {/*
              modified : Ashutosh G
              modified : 24/01/2024
              modification : Search button added
            */}
            <Button
              kind="primary"
              style={{ marginTop: "1.5rem" }}
              onClick={getNonProfits}
              disabled={!searchTerm || !selectedCauses.length > 0}
            >
              Search
            </Button>
          </div>

          {/*
            modified : Ashutosh G
            modified : 12/12/2023
            modification : Select cause and donation card with btn added below
          */}
          {/*<Select
            className="selectbox after-select select"
            value={searchTerm}
            label="Select Cause"
            onChange={changeSearchTerm}
            isFullWidth
            options={allCauses}
          />*/}
          {/*
            modified : Ashutosh G
            modified : 14/12/2023
            modification : loading box and No Data Found css margin added to not flicker
          */}
          {/*
            modified : Ashutosh G
            modified : 18/01/2024
            modification : if got 429 error then show error msg
          */}
          {
            isLoading
             ?  <div style={loadingBoxStyle}> <img src={LoadingGif} width="40px" height="40px" alt="Loading Gif" /> </div>
             : nonProfitsData.length === 0
              ? <div style={loadingBoxStyle}>{message}</div>
              : <div>
                   {
                     (response && response.paginatedData && response.paginatedData.length !== 0) && <ResponsivePagination
                       current={pageNumber}
                       total={totalPages}
                       onPageChange={setPageNumber}
                     />
                   }
                   <div style={{ marginTop: "1rem" }} ref={nonProfitRef}>
                    {renderedNonProfits}
                   </div>
                   {
                     (response && response.paginatedData && response.paginatedData.length !== 0) && <ResponsivePagination
                       current={pageNumber}
                       total={totalPages}
                       onPageChange={setPageNumber}
                     />
                   }
                </div>
          }
      </div>
  );
}
