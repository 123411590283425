/* eslint-disable react/prefer-stateless-function */
import React, { PureComponent } from "react"
import { BrowserRouter as Router, Route } from "react-router-dom"

import { Authenticated } from "./routes/Authenticated"
import { Unauthenticated } from "./routes/Unauthenticated"

import GlobalFonts from "./assets/fonts/fonts"
import axios from "axios"
import CONSTANTS from "./lib/constants"
import { Login } from "./pages/Gateway/Login/Login"
import history from './lib/history';
import moment from "moment"
import { OverlayComp } from "./assets/components/CommonComponents";
import {
    Element,
    defaultColours as baseColors
} from "fictoan-react"

import feather from "feather-icons"

import { removeAll, getItem } from "./lib/myStore"

import {
    NotificationItem,
    NotificationsWrapper,
} from "fictoan-react"

//  Switching this will let you access pre-login and post-login screens.
//  Implement custom logic, don’t use this flag!
const isUserAuthenticated = true

// window.addEventListener('beforeunload', function (e) {
//     e.preventDefault();
//     e.returnValue = '';
// });
var validNavigation = 0;


function endSession()
{
   // Browser or Broswer tab is closed
   // Write code here
   alert('Browser or Broswer tab closed');
}

function bindDOMEvents() {
/*

* unload works on both closing tab and on refreshing tab.

*/
window.unload = function()
{
   if (validNavigation==0)
   {
     endSession();
   }
}

// Attach the event keypress to exclude the F5 refresh
window.keydown = function(e)
{
   var key=e.which || e.keyCode;
   if (key == 116)
   {
     validNavigation = 1;
   }
};



}

// window.addEventListener("onload", function()
// {
//    bindDOMEvents();
// });

window.onbeforeunload = function () {
  //removeAll()
  // axios.post(`${CONSTANTS.API_URL}/api/v1/logout`).then(async (response) => {
  //     // history.push("/?out=true")
  // })
};


class App extends PureComponent {
    render() {
        return (
            <Router>
                <GlobalFonts />
                {/*isUserAuthenticated ? (
                    <Route path="/" component={Authenticated} />
                ) : (
                    <Route path="/" component={Unauthenticated} />
                )*/}
                <Route path="/" component={Unauthenticated} />
            </Router>
        )
    }
}

export default App
