import styled from "styled-components";
import { defaultColours } from "fictoan-react"

export const DropdownStyled = styled.article`

min-height:auto;
padding-bottom: 0 !important;

.progress-wrapper {
    font-size      : 80%;
    text-transform : uppercase;
    color          : ${defaultColours.slate40};
}
.margin-bottom-tiny {
    margin-bottom: 2vmax !important;
}
.dropdown {
  width: 170px;
}

.dropdown .dropdown-btn {
  cursor: pointer;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: white;
  border: none;
  border-radius: 5px;
  color: #212b2d;
  font-weight: 500;
}
.dropdown-content {
  position: absolute;
  width: 13%;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
  z-index: 1;
}

.dropdown-content .item {
  padding: 10px;
  cursor: pointer;
}
.dropdown-content .item:hover {
  background: #fcfcfc;
}

button {
  z-index: -1;
  display: block;
  width: 300px;
  margin: 0 auto;
}

Select {
  border:none;
  font-size: 17px;
  font-weight: 500;
  cursor: pointer
}

.after-select::before {
  content: "";
  position: absolute;
  top: 50%;
  right:35px;
  height: 20px; /* Adjust the height as needed */
  width: 1px; /* Adjust the width as needed */
  background-color: #D0D0D0;
  transform: translateY(-50%);
}


.after-select::after{
  content: "";
  display: inline-block;
  position: absolute;
  top: 40%;
  right: 12px;
  width: 8px;
  height: 8px;
  border-style: solid;
  border-width: 0 3px 3px 0;
  transform: translateY(-50%)rotate(45deg);
  color:#C0C0C0;
  transition: transform 0.2s linear;
  z-index: 500;
  pointer-events: none;
}

.dropdown-container {
  position: relative;
  display: inline-block;
}

.dropdown-menu {
  position: absolute;
  top: 110%;
  left: -84px;
  width: 130px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  border: 1px solid #ccc;
  padding: 8px;
  z-index: 1;
  display: none; /* Initially hide the dropdown menu */
}

.dropdown-menu div {
  padding: 8px;
  cursor: pointer;
}

.dropdown-menu div:hover {
  background-color: #f0f0f0;
}

/* Show the dropdown menu when the button is clicked */
.dropdown-container:hover .dropdown-menu {
  display: block;
}


/*
  Date: 25th September, 2023
  By: Akanksha Malviya
  Modification: added responsiveness to Solutions dropdown
*/

@media only screen and (max-width: 640px) {
   .dropdown {
     display: none
   }


`
