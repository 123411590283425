/*
  modified : Ashutosh G
  modified : 20/11/2023
  modification : ToastifyComponent component added
*/
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import { useEffect } from 'react';

export default function ToastifyComponent ({ isOpen, setIsOpen, options}) {

  useEffect(() => {
    if (isOpen) {
      Toastify({
        ...options
      }).showToast();
    }
  },[isOpen]);

  return null;
}
