import styled from "styled-components"
import { defaultColours as baseColors } from "fictoan-react"

export const LoadingStyled = styled.div`
    display: flex;
    align-items: center;
    width: ${(props) => (props.centered ? "100%" : "auto")};
    justify-content: ${(props) => (props.centered ? "center" : "initial")};

    .loading-spinner {
        .ripple {
            display: inline-block;
            position: relative;
            width: ${(props) => (props.size === "large" ? "64px" : "32px")};
            height: ${(props) => (props.size === "large" ? "64px" : "32px")};
        }
        .ripple div {
            position: absolute;
            border: 4px solid #2874a6;
            opacity: 1;
            border-radius: 50%;
            animation: ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
            top: 4px;
            left: 0;
            right: 0;
            bottom: -4px;
        }
        .ripple div:nth-child(2) {
            animation-delay: -0.33s;
        }
        .ripple div:nth-child(3) {
            animation-delay: -0.66s;
        }
        @keyframes ripple {
            0% {
                transform: scale(0);
                opacity: 1;
            }
            100% {
                transform: scale(1);
                opacity: 0;
            }
        }
    }
`
