import React,{useState, useEffect} from "react"
import feather from "feather-icons"
import { NavLink, useLocation, useHistory } from "react-router-dom"
import Logo from "../../../images/Logo1.png";
import Fab from "../../../images/Fab.png";
import axios from "axios";
import CONSTANTS from "../../../../lib/constants";
import Cookies from 'js-cookie';
import { getItem, removeAll } from '../../../../lib/myStore';
import Modal from "../../AnalysisComponents/Modal";

import {
    SidebarWrapper,
    SidebarHeader,
    SidebarItemsGroup,
    SidebarItemsGroupHeader,
    SidebarItem,
    SidebarItemIcon,
    SidebarItemText,
    HRule,
    Button
} from "fictoan-react"

import icon from "../../../images/analytics.svg"
import { useActiveLinkSet } from "./utils"
import { FilePreviewer } from "../FilePreviewer";
import { ContributeModal } from "../../CommonComponents";
import { removeLinkedinCookies } from '../../../../lib/cookies';

export const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false)
  const { activeLinkSet: dynamicActiveLinkSet } = useActiveLinkSet()
  const [activeLinkset, setActiveLinkset] = useState(dynamicActiveLinkSet)
  const [activePathName, setActivePathName] = useState(window.location.pathname)
  let history = useHistory()
  const location = useLocation();
  const [selectedValue, setSelectedValue] = useState('')
  const userInfo = getItem('userinfo');
  const [filePreview, setFilePreview] = useState({
      loading: false,
      forceClosed: true
  });
  const [unregisterModal, setUnregisterModal] = useState({
      loading: false,
      forceClosed: true
  });
  const router = useHistory();

  useEffect(() => {
      setActivePathName(window.location.pathname)
      console.log(window.location.pathname,'window.location.pathname');
      if (window.location.pathname === '/analysis') {
        const searchParams = new URLSearchParams(location.search);
        const selectedValue = searchParams.get('selectedValue');
        setSelectedValue(selectedValue)
      } else {
        setSelectedValue('')
      }


    },[window.location.pathname]);


    const logout = async (e) => {
      e.preventDefault();
      console.log('logout');

      let token = getItem("token") ? getItem("token").token : false;
      if(token){
        let res = await axios.post(`${CONSTANTS.API_URL}/api/v1/logout`);

        if (res) {
          removeAll();
          /*
            modified : Ashutosh G
            modified : 10/11/2023
            modification : removing linkdinToken and token from cookie after clearing localstorage
          */
          removeLinkedinCookies();
          // window.location.href = "/"
          history.push("/")
          // history.replace('/');
        }
        // setShowList(!showList)
      } else{
        history.push("/");
      }

      // checkIsLogin();
    };

    const toggleFilePreview = () => {
      setFilePreview(prevValue => { return { ...prevValue, forceClosed: !prevValue.forceClosed } })
    }

    /*
      modified : Ashutosh G
      modified : 22/11/2023
      modification : unregisterUser function added to deactivate user and delete cookies and clean localstorage
    */
    const toggleUnregisterPopup = () => {
      setUnregisterModal(prevValue => { return { ...prevValue, forceClosed: !prevValue.forceClosed } })
    }

    async function unregisterUser() {
        try {
          let response = await axios.put(`${CONSTANTS.API_URL}/api/v1/user/Deactivate`, { userIDs: userInfo.uuid });
          removeAll();
          removeLinkedinCookies();
          router.push("/");
        } catch (error) {
          console.log(error);
        }
    }

    return (
        <>
          <SidebarWrapper id="app-sidebar" className={`no-scrollbar ${collapsed ? "collapsed" : ""}`} style={{'overflowX': 'hidden', left: '0px', background:'#fff'}}>
            <SidebarHeader onClick={() => setCollapsed(!collapsed)}>
              <img src={Logo} className="header-logo" alt="TT logo" onContextMenu={(e) => { e.preventDefault(); }} draggable="false" />
              <img src={Fab} className="header-icon" alt="TT icon" onContextMenu={(e) => { e.preventDefault(); }} draggable="false" />
            </SidebarHeader>

              <div id="linkset-wrapper" className={`${activeLinkset}-active`}>
                  {
                    <>
                        <div id="linkset-1" style={{height: 'calc( 100vh - 18vh)'}}>

                          {/*<NavLink exact to="/home">
                              <SidebarItem>
                                  <SidebarItemIcon
                                      iconType="stroked"
                                      dangerouslySetInnerHTML={{
                                          __html: feather.icons["home"].toSvg({
                                              width: 16,
                                              height: 16,
                                          }),
                                      }}
                                  />
                                  <SidebarItemText weight="600" linkText="Home" />
                              </SidebarItem>
                          </NavLink>*/}
                          <HRule className="margin-top-none margin-bottom-none" />
                              <SidebarItemsGroup open={activePathName.includes('/analysis')}>
                                  <SidebarItemsGroupHeader>
                                      <SidebarItem>
                                          <SidebarItemIcon
                                              iconType="stroked"
                                              dangerouslySetInnerHTML={{
                                                  __html: feather.icons["file-text"].toSvg({
                                                      width: 16,
                                                      height: 16,
                                                  }),
                                              }}
                                          />
                                          <SidebarItemText style={{fontWeight: '600 !important'}} linkText={`Solutions`} weight="600" />
                                      </SidebarItem>
                                  </SidebarItemsGroupHeader>
                                  <NavLink exact to="/analysis/topicalHeatMap">
                                      <SidebarItem>
                                          <SidebarItemIcon
                                              iconType="stroked"
                                              dangerouslySetInnerHTML={{
                                                  __html: feather.icons["grid"].toSvg({
                                                      width: 16,
                                                      height: 16,
                                                  }),
                                              }}
                                          />
                                          <SidebarItemText weight="100" linkText="Topical Heatmap" />
                                      </SidebarItem>
                                  </NavLink>

                                  <NavLink to="/analysis/seasonalSelection">
                                      <SidebarItem>
                                          <SidebarItemIcon
                                              iconType="stroked"
                                              dangerouslySetInnerHTML={{
                                                  __html: feather.icons["bar-chart"].toSvg({
                                                      width: 16,
                                                      height: 16,
                                                  }),
                                              }}
                                          />
                                          <SidebarItemText weight="100" linkText="Seasonal Selection" />
                                      </SidebarItem>
                                  </NavLink>

                                  <NavLink to="/analysis/marketPrioritization">
                                      <SidebarItem>
                                          <SidebarItemIcon
                                              iconType="stroked"
                                              dangerouslySetInnerHTML={{
                                                  __html: feather.icons["map-pin"].toSvg({
                                                      width: 16,
                                                      height: 16,
                                                  }),
                                              }}
                                          />
                                          <SidebarItemText weight="100" linkText="Priority Markets" />
                                      </SidebarItem>
                                  </NavLink>

                                  <NavLink to="/analysis/corelatedCause">
                                      <SidebarItem>
                                          <SidebarItemIcon
                                              iconType="stroked"
                                              dangerouslySetInnerHTML={{
                                                  __html: feather.icons["table"].toSvg({
                                                      width: 16,
                                                      height: 16,
                                                  }),
                                              }}
                                          />
                                          <SidebarItemText weight="100" linkText="Correlated Causes" />
                                      </SidebarItem>
                                  </NavLink>


                              </SidebarItemsGroup>
                    {
                      userInfo.isSiloAdmin &&
                      <>
                        <HRule className="margin-top-none margin-bottom-none" />
                        <SidebarItemsGroup open={activePathName.includes('/admin')}>
                            <SidebarItemsGroupHeader>
                                <SidebarItem>
                                    <SidebarItemIcon
                                        iconType="stroked"
                                        dangerouslySetInnerHTML={{
                                            __html: feather.icons["user"].toSvg({
                                                width: 16,
                                                height: 16,
                                            }),
                                        }}
                                    />
                                    <SidebarItemText
                                        style={{width: '160px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'}}
                                        linkText={`Admin`}
                                    />
                                </SidebarItem>
                            </SidebarItemsGroupHeader>
                            <NavLink exact to="/manageSlots">
                                <SidebarItem>
                                    <SidebarItemIcon
                                        iconType="stroked"
                                        dangerouslySetInnerHTML={{
                                            __html: feather.icons["layout"].toSvg({
                                                width: 16,
                                                height: 16,
                                            }),
                                        }}
                                    />
                                    <SidebarItemText weight="100" linkText="Manage Slots" />
                                </SidebarItem>
                            </NavLink>
                            {/*<NavLink target='_blank' to={`https://docs.google.com/forms/d/e/1FAIpQLScn6QAImjxzVMmsg3CbeP0bZDHX56peIYDwBpFQUm8FZI_oCQ/viewform?usp=sf_link`} >
                                <SidebarItem>
                                    <SidebarItemIcon
                                        iconType="stroked"
                                        dangerouslySetInnerHTML={{
                                            __html: feather.icons["user"].toSvg({
                                                width: 16,
                                                height: 16,
                                            }),
                                        }}
                                    />
                                    <SidebarItemText weight="100" linkText="Feedback" />
                                </SidebarItem>
                            </NavLink>*/}
                            <NavLink exact to="/admin/users">
                                <SidebarItem>
                                    <SidebarItemIcon
                                        iconType="stroked"
                                        dangerouslySetInnerHTML={{
                                            __html: feather.icons["users"].toSvg({
                                                width: 16,
                                                height: 16,
                                            }),
                                        }}
                                    />
                                    <SidebarItemText weight="100" linkText="Users" />
                                </SidebarItem>
                            </NavLink>
                            <NavLink exact to="/admin/sessions">
                                <SidebarItem>
                                    <SidebarItemIcon
                                        iconType="stroked"
                                        dangerouslySetInnerHTML={{
                                            __html: feather.icons["circle"].toSvg({
                                                width: 16,
                                                height: 16,
                                            }),
                                        }}
                                    />
                                    <SidebarItemText weight="100" linkText="Sessions" />
                                </SidebarItem>
                            </NavLink>
                            <NavLink exact to="/admin/reports">
                                <SidebarItem>
                                    <SidebarItemIcon
                                        iconType="stroked"
                                        dangerouslySetInnerHTML={{
                                            __html: feather.icons["file-text"].toSvg({
                                                width: 16,
                                                height: 16,
                                            }),
                                        }}
                                    />
                                    <SidebarItemText weight="100" linkText="Reports" />
                                </SidebarItem>
                            </NavLink>
                            {/*
                              modified : Ashutosh G
                              modified : 12/12/2023
                              modification : contributions table added for admin
                            */}
                            <NavLink exact to="/admin/contributions">
                                <SidebarItem>
                                    <SidebarItemIcon
                                        iconType="stroked"
                                        dangerouslySetInnerHTML={{
                                            __html: feather.icons["file-text"].toSvg({
                                                width: 16,
                                                height: 16,
                                            }),
                                        }}
                                    />
                                    <SidebarItemText weight="100" linkText="Contribution History" />
                                </SidebarItem>
                            </NavLink>
                        </SidebarItemsGroup>
                      </>
                    }
                    {
                      !userInfo.isSiloAdmin &&
                      <>
                        <HRule className="margin-top-none margin-bottom-none" />
                          <NavLink exact to="/admin/reports">
                              <SidebarItem>
                                  <SidebarItemIcon
                                      iconType="stroked"
                                      dangerouslySetInnerHTML={{
                                          __html: feather.icons["file-text"].toSvg({
                                              width: 16,
                                              height: 16,
                                          }),
                                      }}
                                  />
                                  <SidebarItemText weight="600" linkText="Your Reports" />
                              </SidebarItem>
                          </NavLink>
                        </>
                    }
                    <HRule marginTop="none" marginBottom="none" />
                    <a target='_blank' href={`https://docs.google.com/forms/d/e/1FAIpQLScn6QAImjxzVMmsg3CbeP0bZDHX56peIYDwBpFQUm8FZI_oCQ/viewform?usp=sf_link`} >
                        <SidebarItem>
                            <SidebarItemIcon
                                iconType="stroked"
                                dangerouslySetInnerHTML={{
                                    __html: feather.icons["user"].toSvg({
                                        width: 16,
                                        height: 16,
                                    }),
                                }}
                            />
                            <SidebarItemText weight="600" linkText="Feedback" />
                        </SidebarItem>
                    </a>
                    <HRule className="margin-top-none margin-bottom-none" />
                    {
                      !userInfo.isSiloAdmin &&
                      <>
                      <NavLink exact to="/event">
                          <SidebarItem>
                              <SidebarItemIcon
                                  iconType="stroked"
                                  dangerouslySetInnerHTML={{
                                      __html: feather.icons["calendar"].toSvg({
                                          width: 16,
                                          height: 16,
                                      }),
                                  }}
                              />
                              <SidebarItemText weight="600" linkText="Slot a catchup" />
                          </SidebarItem>
                      </NavLink>
                      {
                        /*
                          modified : Ashutosh G
                          modified : 13/12/2023
                          modification : HRule added
                        */
                      }
                      <HRule className="margin-top-none margin-bottom-none" />
                      </>
                    }
                    {/*<HRule marginTop="none" marginBottom="none" />
                    <NavLink exact to="/contribute-table">
                        <SidebarItem>
                            <SidebarItemIcon
                                iconType="stroked"
                                dangerouslySetInnerHTML={{
                                    __html: feather.icons["table"].toSvg({
                                        width: 16,
                                        height: 16,
                                    }),
                                }}
                            />
                            <SidebarItemText weight="600" linkText="Payments" />
                        </SidebarItem>
                    </NavLink>*/}
                    {/*
                      modified : Ashutosh G
                      modified : 11/12/2023
                      modification : contribute uncommeted
                    */}
                            <div onClick={() => toggleFilePreview()}>
                                <SidebarItem style={{ cursor: "pointer" }}>
                                    <SidebarItemIcon
                                        iconType="stroked"
                                        dangerouslySetInnerHTML={{
                                            __html: feather.icons["dollar-sign"].toSvg({
                                                width: 16,
                                                height: 16,
                                            }),
                                        }}
                                    />
                                    <SidebarItemText weight="600" linkText="Contribute" />
                                </SidebarItem>
                            </div>
                    {
                      /*
                        modified : Ashutosh G
                        modified : 12/12/2023
                        modification : My Contributions tab added for contribution table
                      */
                    }
                    {
                      !userInfo.isSiloAdmin &&
                      <>
                        <HRule className="margin-top-none margin-bottom-none" />
                          <NavLink exact to="/admin/contributions">
                              <SidebarItem>
                                  <SidebarItemIcon
                                      iconType="stroked"
                                      dangerouslySetInnerHTML={{
                                          __html: feather.icons["file-text"].toSvg({
                                              width: 16,
                                              height: 16,
                                          }),
                                      }}
                                  />
                                  <SidebarItemText weight="600" linkText="My Contributions" />
                              </SidebarItem>
                          </NavLink>
                      </>
                    }

                    <HRule marginTop="none" marginBottom="none" />

                            <div onClick={logout}>
                                <SidebarItem style={{ cursor: "pointer" }}>
                                    <SidebarItemIcon
                                        iconType="stroked"
                                        dangerouslySetInnerHTML={{
                                            __html: feather.icons["power"].toSvg({
                                                width: 16,
                                                height: 16,
                                            }),
                                        }}
                                    />
                                    <SidebarItemText weight="600" linkText="Logout" />
                                </SidebarItem>
                            </div>
                        </div>
                        {
                          !userInfo.isSiloAdmin && (
                            <div onClick={toggleUnregisterPopup}>
                              <SidebarItem style={{ cursor: "pointer" }}>
                                <SidebarItemIcon
                                  iconType="stroked"
                                  dangerouslySetInnerHTML={{
                                    __html: feather.icons["user-minus"].toSvg({
                                    width: 16,
                                    height: 16,
                                  }),
                                  }}
                                />
                                <SidebarItemText weight="600" linkText="Unregister" />
                              </SidebarItem>
                           </div>
                          )
                        }

                    </>
                  }
              </div>
          </SidebarWrapper>
          {/*
            modified : Ashutosh G
            modified : 12/12/2023
            modification : toggleContributeModal passed to ContributeModal component
          */}
          <FilePreviewer loading={filePreview.loading} visible={!filePreview.forceClosed} onClose={toggleFilePreview}>
             <div>
               <ContributeModal toggleContributeModal={toggleFilePreview}/>
             </div>
          </FilePreviewer>
          {/*
            modified : Ashutosh G
            modified : 22/11/2023
            modification : unregister pop-up added
          */}
          {/*
            modified : Ashutosh G
            modified : 24/11/2023
            modification : modal css change
          */}
          {/*
            modified : Ashutosh G
            modified : 01/01/2024
            modification : isFullHeader={false} prop added in FilePreviewer for not fixed header
          */}
          <FilePreviewer loading={unregisterModal.loading} visible={!unregisterModal.forceClosed} onClose={toggleUnregisterPopup} modalCSS={{ width: "100px", height: "260px", modalBorder: "3px solid #ffb400" }} isFullHeader={false}>
             <div style= {{ textAlign: "left" }}>
               <h3 style={{ marginBottom: "10px", textAlign: "center" }}>Are you sure?</h3>
               <p style={{ marginBottom: "20px", lineHeight: "22px" }}>
                  Sorry to see you go. <br/> Once you confirm we will delete your personal information and mark your profile as deactivatied.
               </p>
               <p style={{ marginBottom: "20px" }}><b>Please note:</b> You will no longer be able to sign in and retrieve your reports.</p>
               <div style={{ display: "flex", justifyContent: "end"}}>
                 <Button type='button' kind="primary" size="small" shadow="hard" marginLeft='nano' onClick={toggleUnregisterPopup}>
                     Cancel
                 </Button>
                 <Button type='button' kind="primary" size="small" shadow="hard" marginLeft='nano' onClick={unregisterUser}>
                     Delete
                 </Button>
               </div>
             </div>
          </FilePreviewer>
        </>
        )
}
