import React from "react";
import {
    Row,
    Portion,
    Card,
    Text,
    Heading
} from "fictoan-react"
import { Type } from "../../helpers/Type";

export default function PurposeInfo(){
  return(
    <Portion desktopSpan="15" hideOnTabletPortrait hideOnMobile>
      <Heading as="h4" weight="400" marginTop="nano">
          Welcome to
      </Heading>
      <p className="company-title">Trend Tonik</p >
      <Row>
         <Card padding="nano" border="none" bgColor="none" style={{ width :"188px", border:'none' }}>
             <Text className="typewriter" textColor="grey" > A free web service for </Text>
         </Card>
         <Card className="typewriterSpan" padding="nano" borderColor="none" bgColor="none" style={{ marginLeft:'-30px',fontWeight:'bold', color:'#ffb400', whiteSpace: "nowrap", border:'none' }} >
             <Type />
         </Card>
      </Row>
    </Portion>
  );
}
