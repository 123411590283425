/*
  modified : Ashutosh G
  modified : 1/11/2023
  modification : Mpdal from titandef project (Modal In center)
*/
// import { defaultColours } from "fictoan-react"
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Icon } from "./Icon"
import { Loading } from "./Loading/Loading"

let defaultColours = {
  slate: "#a97f1d",
};

export const FilePreviewer = ({ loading, visible, children, fileName, modalCSS = {}, isFullHeader = true, onClose = () => {} }) => {
    const [headerStyle, setHeaderStyle] = useState({ position:'sticky', marginTop: '20px', paddingRight: '1rem' });
    /*
      modified : Ashutosh G
      modified : 24/11/2023
      modification : modal border css added
    */
    const FilePreviewerContainer = styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 100000;
        background: rgba(0, 0, 0, 0.7);
        padding: 2rem;

        > div {
            position: relative;
            width: 50vw;
            max-width: 50vw;
            height: ${modalCSS.height ? modalCSS.height : "90vh"};
            max-height: ${modalCSS.height ? modalCSS.height : "90vh"};
            border-radius: 5px;
            border: ${modalCSS.modalBorder ? modalCSS.modalBorder : `10px solid ${defaultColours.slate}` };
            background: white;
            overflow-y: auto;
            overflow-x: hidden;

            > span.close {
                position: absolute;
                top: 14px;
                right: 10px;
                z-index: 100002;
                color: #000;
                text-decoration: none;
            }
        }

        > div > div.title {
            position: sticky;
            padding: 1rem 2rem;
            background: white;
            z-index: 100001;
            font-weight: 600;
            border-bottom: 1px solid ${defaultColours.slate};
            top: 0;
        }

        > div > div.content {
            padding: 1rem 2rem;
        }

        @media (max-width: 767px) {
            > div {
                width: 100vw;
                max-width: 100vw;
            }
        }
    `

    /*
      modified : Ashutosh G
      modified : 24/01/2024
      modification : changing headerStyle depening on isFullHeader
    */
    useEffect(() => {
      if (isFullHeader) {
        setHeaderStyle(prevValue => { return { ...prevValue, width: "100%", textAlign: "right", background: "inherit", top: 0, marginTop: 0, padding: "1rem" } });
      }
    }, [isFullHeader]);

    if (!visible) return <></>
    return (
        <FilePreviewerContainer>
            {/*
              modified : Ashutosh G
              modified : 14/12/2023
              modification : myCustomScrollbar class added
            */}
            <div className="myCustomScrollbar">
                <span className="close" style={headerStyle}>
                    <Icon icon="x" size={24} onClick={onClose} style={{ cursor: "pointer" }} />
                </span>
                <div className="content">
                    {loading && <Loading centered />}
                    {!loading && children}
                </div>
            </div>
        </FilePreviewerContainer>
    )
}
