import styled from "styled-components";
import { defaultColours } from "fictoan-react"

export const FooterStyled = styled.article`

min-height:auto;
padding-bottom: 0 !important;

.dash {
    width: 156px;
}


@media only screen and (max-width: 640px) {
   .text1 {
     font-size: 10px;
   }

   .text2 {
     font-size: 8px;
   }

   .dash {
    width: 100px;
   }

`
