import styled from "styled-components";
import { defaultColours } from "fictoan-react"

export const NavbarStyled = styled.article`

min-height:auto;
padding-bottom: 0 !important;

.progress-wrapper {
    font-size      : 80%;
    text-transform : uppercase;
    color          : ${defaultColours.slate40};
}
.margin-bottom-tiny {
    margin-bottom: 2vmax !important;
}

.navbarText {
  font-size: 32px;
  font-weight: 700;
  color: #0000000
}

.justifyBetween {
  display:flex;
  justify-content:space-between;
}

.login-btn {
  width: 100px;
  background-color: #ffc3149C;
  borderRadius: 2px
}

.user-icon {
  background-color: #ffb400;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer
}

.dropdown-container {
  position: relative;
  display: inline-block;
}

.dropdown-menu {
  position: absolute;
  top: 110%;
  left: -84px;
  width: 130px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  border: none;
  padding: 8px;
  z-index: 1;
  display: none; /* Initially hide the dropdown menu */
}

.dropdown-menu div {
  padding: 8px;
  cursor: pointer;
}

.dropdown-menu div:hover {
  background-color: #f0f0f0;
}

/* Show the dropdown menu when the button is clicked */
.dropdown-container .dropdown-menu {
  display: block;
}


.dropdown {
  width: 190px;
}

.dropdown .dropdown-btn {
  cursor: pointer;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: white;
  border: none;
  border-radius: 5px;
  color: #212b2d;
  font-weight: 500;
}
.dropdown-content {
  position: absolute;
  width: 13%;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
  z-index: 1;
  background: #fff;
}

.dropdown-content .item {
  padding: 10px;
  cursor: pointer;
}
.dropdown-content .item:hover {
  background: #fcfcfc;
}

button {
  z-index: -1;
  display: block;
  width: 300px;
  margin: 0 auto;
}

.side-drawer {
   background: white;
   border-right: none;
   position: fixed;
   top: 0;
   right: 0;
   width: 100%;
   height:100%;
   padding:10px;
   z-index: 9999 !important;
   box-shadow: 1px 0px 7px rgba(0,0,0,0.5);
   transform: translateX(100%);
   transition: transform 1s ease;
}
.side-drawer.open {
   transform: translateX(0);
}

@media only screen and (max-width: 640px){
  .navbarText {
    font-size: 18px;
    font-weight: 700;
    color: #0000000
    align: center;
  }
}


}


`
