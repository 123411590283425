/* eslint-disable react/prefer-stateless-function */
import React, { Suspense, PureComponent } from "react"
import { Switch, Route } from "react-router-dom"
import { ContentWrapper, ThemeProvider } from "fictoan-react"

import { Sidebar } from "../assets/components/Global/Sidebar/Sidebar"

import { TDTheme } from "../styles/Theme"
import { GlobalsStyle } from "../styles/Globals.styled"
const Error404 = React.lazy(() =>
  import('../pages/404/404').then(module => ({ default: module.PrivaError404 }))
);

export const Authenticated = () => {
    return (
        <ThemeProvider theme={TDTheme}>
            <GlobalsStyle />

            <Sidebar />

            <ContentWrapper>
            <Suspense fallback={<div></div>}>
                <Switch>

                    <Route component={Error404} />
                </Switch>
                </Suspense>
            </ContentWrapper>
        </ThemeProvider>
    )
}
