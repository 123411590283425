import React, { createContext, useState, useContext } from 'react';


{/*
  Date: 20th September, 2023
  By: Akanksha Malviya
  Modification: Context was created for setting components. Currently not in use
*/}

const DropdownContext = createContext();

export const DropdownProvider = ({ children }) => {
  const [selectedValue, setSelectedValue] = useState("");

  const updateSelectedValue = (newValue) => {
    setSelectedValue(newValue);
  };

  return (
    <DropdownContext.Provider value={{ selectedValue, updateSelectedValue }}>
      {children}
    </DropdownContext.Provider>
  );
};

export const useDropdown = () => {
  return useContext(DropdownContext);
};
