import styled from "styled-components";
import { defaultColours } from "fictoan-react"

export const NavbarStyled = styled.article`

min-height:auto;
padding-bottom: 0 !important;
position: sticky;
WIDTH: 100%;
height: 70px;
top: 0;
z-index: 9999;
BACKGROUND: #fff;
box-shadow: rgb(0 0 0 / 10%) 0px 10px 15px -3px, rgb(0 0 0 / 5%) 0px 4px 6px -2px;


.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  list-style: none;
}
.menu-li-active {
  border-bottom: 2px solid #fb0;
}

.menu .menu-li {
  width: 90px;
  height: 50px;
  transition: background-position-x 0.9s linear;
  text-align: center;

  a {
    font-size: 22px;
    color: #777;
    text-decoration: none;
    transition: all 0.45s;
  }

  &:hover {
    border-bottom: 2px solid #fb0;
    //background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB2ZXJzaW9uPSIxLjEi%0D%0AIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhs%0D%0AaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB3aWR0%0D%0AaD0iMzkwcHgiIGhlaWdodD0iNTBweCIgdmlld0JveD0iMCAwIDM5MCA1MCIgZW5hYmxlLWJhY2tn%0D%0Acm91bmQ9Im5ldyAwIDAgMzkwIDUwIiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHBhdGggZmlsbD0i%0D%0Abm9uZSIgc3Ryb2tlPSIjZDk0ZjVjIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLW1pdGVybGlt%0D%0AaXQ9IjEwIiBkPSJNMCw0Ny41ODVjMCwwLDk3LjUsMCwxMzAsMAoJYzEzLjc1LDAsMjguNzQtMzgu%0D%0ANzc4LDQ2LjE2OC0xOS40MTZDMTkyLjY2OSw0Ni41LDI0My42MDMsNDcuNTg1LDI2MCw0Ny41ODVj%0D%0AMzEuODIxLDAsMTMwLDAsMTMwLDAiLz4KPC9zdmc+Cg==");
    animation: line 1s;
  }

  &:hover a {
    color: #d94f5c;
  }

  &:not(:last-child) {
    //margin-right: 30px;
  }
}

@keyframes line {
  0% {
    background-position-x: 390px;
  }
}

.dropdown-menu-li {
  a {
    font-size: 0.9375em !important;
    font-weight: 400;
    color: #000 !important;
  }
}

.progress-wrapper {
    font-size      : 80%;
    text-transform : uppercase;
    color          : ${defaultColours.slate40};
}
.margin-bottom-tiny {
    margin-bottom: 2vmax !important;
}

.navbarText {
  font-size: 32px;
  font-weight: 700;
  color: #0000000
}

.justifyBetween {
  display:flex;
  justify-content:space-between;
}

.login-btn {
  width: 100px;
  background-color: #ffc3149C;
  borderRadius: 2px
}

.user-icon {
  background-color: #ffb400;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer
}

.dropdown-container {
  position: relative;
  display: inline-block;
}

.dropdown-menu {
  position: absolute;
  top: 124%;
  left: -108px;
  width: 130px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  border: none;
  padding: 8px;
  z-index: 1;
  display: none; /* Initially hide the dropdown menu */
}

.dropdown-menu div {
  padding: 8px;
  cursor: pointer;
}

.dropdown-menu div:hover {
  background-color: #f0f0f0;
}

/* Show the dropdown menu when the button is clicked */
.dropdown-container .dropdown-menu {
  display: block;
}


.dropdown {
  width: 190px;
}

.dropdown .dropdown-btn {
  cursor: pointer;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: white;
  border: none;
  border-radius: 5px;
  color: #212b2d;
  font-weight: 500;
}
.dropdown-content {
  position: absolute;
  width: 13%;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
  z-index: 1;
  background: #fff;
}

.dropdown-content .item {
  padding: 10px;
  cursor: pointer;
}
.dropdown-content .item:hover {
  background: #fcfcfc;
}

button {
  z-index: -1;
  display: block;
  width: 300px;
  margin: 0 auto;
}

.side-drawer {
   background: white;
   border-right: none;
   position: fixed;
   top: 0;
   right: 0;
   width: 100%;
   height:100%;
   padding:10px;
   z-index: 9999 !important;
   box-shadow: 1px 0px 7px rgba(0,0,0,0.5);
   transform: translateX(100%);
   transition: transform 1s ease;
}
.side-drawer.open {
   transform: translateX(0);
}
.list-item-resources {
  font-size: 14px !important;
  font-weight: 400;
}

@media only screen and (max-width: 640px){
  .navbarText {
    font-size: 18px;
    font-weight: 700;
    color: #0000000
    align: center;
  }
}


}


`
