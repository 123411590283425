import { createGlobalStyle } from 'styled-components';

import PoppinsRegular400 from "./PoppinsRegular400.ttf";
import PoppinsMedium500 from "./PoppinsMedium500.ttf";

export default createGlobalStyle`
    @font-face {
        font-family: "PoppinsRegular400";
        src: local("PoppinsRegular400"), url(${PoppinsRegular400}) format("truetype");
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: "PoppinsMedium500";
        src: local("PoppinsMedium500"), url(${PoppinsMedium500}) format("truetype");
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: "PoppinsMedium500";
        src: local("PoppinsMedium500"), url(${PoppinsMedium500}) format("truetype");
        font-weight: 600;
        font-style: normal;
    }
`;
