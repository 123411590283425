import React from "react"
import { LoadingStyled } from "./Loading.styled"
import { Element, Text } from "fictoan-react"

/**
 * props accepted by this component:
 * centered (boolean)
 * size (string | null -> "large" | null)
 * loadingText (string)
 */

 {/*
   Modified By: Akanksha Malviya
   Modified On: 25th july 2023
   Modification: Added padding to loading component
 */}
export const Loading = ({ centered, size, loadingText = "Loading..." }) => {
    return (
        <LoadingStyled centered={centered} size={size}>
            <Element padding="micro" as="div" marginRight="nano" className="loading-spinner">
                <div className="ripple">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </Element>
            <Text size={size === "large" ? "large" : ""}>{loadingText}</Text>
        </LoadingStyled>
    )
}
