import React from "react"
import { Element } from "fictoan-react"
import feather from "feather-icons"

export const Icon = ({ icon, size = 14, svgOptions = {}, style = {}, ...otherProps } = {}) => {
    return (
        <Element
            as="span"
            {...otherProps}
            style={{ lineHeight: 0, ...style }}
            dangerouslySetInnerHTML={{
                __html: feather.icons[icon].toSvg({ width: size, height: size, ...svgOptions }),
            }}
        />
    )
}
