import { useLocation } from 'react-router'
export const useActiveLinkSet = () => {
    const { pathname } = useLocation()
    let activeLinkSet = 'linkset-1'
    if (pathname.includes('/ffiec')) {
        activeLinkSet = 'linkset-2'
    }
    if (pathname.includes('/finra')) {
        activeLinkSet = 'linkset-3'
    }
    // if (pathname.includes('/cis')) {
    //     activeLinkSet = 'linkset-3'
    // }
    if (pathname.includes('/mspDashboard') || pathname.includes('/mspAdmin')) {
        activeLinkSet = 'linkset-4'
    }
    if (pathname.includes('/ffiec')) {
        activeLinkSet = 'linkset-6'
    }
    if (pathname.includes('/finra')) {
        activeLinkSet = 'linkset-7'
    }
    return {
        activeLinkSet
    }
}
