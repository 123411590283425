import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import axios from 'axios';
import { getItem, setItem, removeAll } from './lib/myStore';
import { useHistory } from 'react-router-dom';
// let history = useHistory();

// // Add a request interceptor
// axios.interceptors.request.use(
//   config => {
//     console.log("In axios request undaw")
//     if (config.url.search('amazonaws') === -1) {
//       if (getItem('token')) {
//         console.log(getItem('token'));
//         const token = getItem('token').token;
//
//         if (token) {
//           config.headers['Authorization'] = token;
//         }
//       }
//       config.headers['Content-Type'] = 'application/json';
//     }
//      return config;
//   },
//   error => {
//     Promise.reject(error);
// });
//
// //Add a response interceptor
// axios.interceptors.response.use((response) => {
//   if(response.data.token) {
//     if (getItem('token')) {
//       let getToken = getItem('token');
//     }
//
//     // console.log(getToken,'getItemgetItem');
//     setItem('token', response.data.token);
//     setItem('lastActivityAt', new Date());
//     // if (getToken && Object.keys(getToken).length)
//
//   }
//   if (response.data == 200) {
//     setItem('lastActivityAt', new Date());
//   }
//    return response
// }, function (error) {
//    const originalRequest = error.config;
//    if (error && error.response && error.response.status === 401) {
//       // window.location = '/login';
//       return Promise.reject(error);
//    } else if (error && error.response && error.response.status === 706) {
//      removeAll();
//      window.location = '/login';
//    } else if (error && error.response && error.response.status === 500) {
//
//      window.location = '/unknown-error';
//    }
//    return Promise.reject(error);
// });

// Add a request interceptor
  axios.interceptors.request.use(
     config => {
       if (config.url.search('amazonaws') === -1) {

         if (getItem('token')) {
          const token = getItem('token').token;
          if (token) {
            config.headers['Authorization'] = token;
          }
        }
         config.headers['Content-Type'] = 'application/json';
       }
       return config;
     },
     error => {
       Promise.reject(error);
     });

  axios.interceptors.request.use(
  config => {
    // console.log(getEncryptedData,"getEncryptedDatagetEncryptedDatagetEncryptedData");
    if (config.data && config.url.search('amazonaws') === -1 && config.url.search('zoop') === -1) {
      config['data'] = JSON.stringify(config.data);
    }
    if (config.url.search('amazonaws') === -1 && config.url.search('zoop') === -1) {
      /*
        T1011
        Modified By: Harshavardhan Jadhav
        Modified On: 12 Nov 2020
        Modification console.log commented
      */
      // console.log("trueeeeeeeee",getItem('token'));
      const token = getItem('token').token;
      if (token) {
        config.headers['Authorization'] = token;
      }
      config.headers['Content-Type'] = 'application/json';
    } else {
      delete config.headers.authorization;
    }
    /*
      T1011
      Modified By: Harshavardhan Jadhav
      Modified On: 12 Nov 2020
      Modification console.log commented
    */
    // console.log(config);
    return config;
  },
  error => {
    Promise.reject(error);
  });

   //Add a response interceptor

// axios.interceptors.response.use((response) => {
//   if(response.data.token) {
//     if (getItem('token')) {
//       let getToken = getItem('token');
//     }
//
//     // console.log(getToken,'getItemgetItem');
//     setItem('token', response.data.token);
//     setItem('lastActivityAt', new Date());
//     // if (getToken && Object.keys(getToken).length)
//
//   }
//   if (response.data == 200) {
//     setItem('lastActivityAt', new Date());
//   }
//    return response
// }, function (error) {
//    const originalRequest = error.config;
//    if (error && error.response && error.response.status === 401) {
//       window.location = '/';
//        return Promise.reject(error);
//    } else if (error && error.response && error.response.status === 706) {
//      removeAll();
//      window.location = '/';
//    } else if (error && error.response && error.response.status === 500) {
//
//      window.location = '/unknown-error';
//    }
//    return Promise.reject(error);
// });

ReactDOM.render(<App />, document.getElementById("root"))
