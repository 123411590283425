import React, {Suspense, useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { matchPath } from "react-router";

import { BrowserView, MobileView, isBrowser, isMobile, deviceDetect, browserName } from "react-device-detect";

import { ContentWrapper, ThemeProvider } from "fictoan-react";

import { GlobalsStyle } from "../styles/Globals.styled";
import { removeAll, getItem } from "../lib/myStore";

import { TDTheme } from "../styles/Theme";
import { Sidebar } from "../assets/components/Global/Sidebar/Sidebar";
import {Navbar} from "../assets/components/Global/Navbar/Navbar";
import {NavbarV2} from "../assets/components/Global/NavbarV2/NavbarV2";
import {Footer} from "../assets/components/Global/Footer/Footer";
import { LoadingScreen } from "../assets/components/CommonComponents";
import Requireauth from "./Requireauth";
// import {DropdownProvider} from "../Context/DropdownContext"

const Login = React.lazy(() =>
  import('../pages/Gateway/Login/Login').then(module => ({ default: module.Login }))
);
const AdminLogin = React.lazy(() =>
  import('../pages/Gateway/AdminLogin/AdminLogin').then(module => ({ default: module.AdminLogin }))
);
const Analysis = React.lazy(() =>
  import('../pages/Analysis/Analysis').then(module => ({ default: module.Analysis }))
);
// const AnalysisTopicalHeatmap = React.lazy(() =>
//   import('../pages/Analysis/AnalysisTopicalHeatmap').then(module => ({ default: module.AnalysisTopicalHeatmap }))
// );
const TopicalHeatMap = React.lazy(() =>
  import('../pages/Analysis/TopicalHeatMap').then(module => ({ default: module.TopicalHeatMap }))
);
// const AnalysisSeasonalSelection = React.lazy(() =>
//   import('../pages/Analysis/AnalysisSeasonalSelection').then(module => ({ default: module.AnalysisSeasonalSelection }))
// );
const SeasonalSelectionAnalysis = React.lazy(() =>
  import('../pages/Analysis/SeasonalSelectionAnalysis').then(module => ({ default: module.SeasonalSelectionAnalysis }))
);
const AnalysisMarketPrioritization = React.lazy(() =>
  import('../pages/Analysis/AnalysisMarketPrioritization').then(module => ({ default: module.AnalysisMarketPrioritization }))
);
// const AnalysisCorelatedCause = React.lazy(() =>
//   import('../pages/Analysis/AnalysisCorelatedCause').then(module => ({ default: module.AnalysisCorelatedCause }))
// );
const CorelatedCauseAnalysis = React.lazy(() =>
  import('../pages/Analysis/CorelatedCauseAnalysis').then(module => ({ default: module.CorelatedCauseAnalysis }))
);
const MarketPrioritizationAnalysis = React.lazy(() =>
  import('../pages/Analysis/MarketPrioritizationAnalysis').then(module => ({ default: module.MarketPrioritizationAnalysis }))
);
const ManageSlots = React.lazy(() =>
  import('../pages/ManageSlots/ManageSlots').then(module => ({ default: module.ManageSlots }))
);

const ManageSlotsAdminDashboard = React.lazy(() =>
  import('../pages/Admin/ManageSlotsAdminDashboard').then(module => ({ default: module.ManageSlotsAdminDashboard }))
);
const UsersAdminDashboard = React.lazy(() =>
  import('../pages/Admin/UsersAdminDashboard').then(module => ({ default: module.UsersAdminDashboard }))
);
const SessionsAdminDashboard = React.lazy(() =>
  import('../pages/Admin/SessionsAdminDashboard').then(module => ({ default: module.SessionsAdminDashboard }))
);
const ReportsAdminDashboard = React.lazy(() =>
  import('../pages/Admin/ReportsAdminDashboard').then(module => ({ default: module.ReportsAdminDashboard }))
);
const ContributionAdminDashboard = React.lazy(() =>
  import('../pages/Admin/ContributionAdminDashboard').then(module => ({ default: module.ContributionAdminDashboard }))
);
const Home = React.lazy(() =>
  import('../pages/Home/Home').then(module => ({ default: module.Home }))
);
const Event = React.lazy(() =>
  import('../pages/Event/Event').then(module => ({ default: module.Event }))
);
const ForgotPassword = React.lazy(() =>
    import('../pages/Gateway/ForgotPassword/ForgotPassword').then(module => ({ default: module.ForgotPassword }))
);
const SignUp = React.lazy(() =>
  import('../pages/Gateway/SignUp/SignUp').then(module => ({ default: module.SignUp }))
);
const SetNewPassword = React.lazy(() =>
  import('../pages/Gateway/SetNewPassword/SetNewPassword').then(module => ({ default: module.SetNewPassword }))
);
const SetPassword = React.lazy(() =>
    import('../pages/Gateway/SetPassword/SetPassword').then(module => ({ default: module.SetPassword }))
);

const Landing = React.lazy(() =>
    import('../pages/Landing/Landing').then(module => ({ default: module.Landing }))
);

const Resources = React.lazy(() =>
    import('../pages/Resources/Resources').then(module => ({ default: module.Resources}))
);

const ContributeTable = React.lazy(() =>
    import('../pages/Contribution/ContributeTable').then(module => ({ default: module.ContributeTable}))
)

const Error404 = React.lazy(() =>
  import('../pages/404/404').then(module => ({ default: module.Error404 }))
);

const PrivacyPolicy = React.lazy(() =>
  import('../assets/components/Global/PrivacyPolicy/PrivacyPolicy.jsx').then(module => ({ default: module.PrivacyPolicy }))
);
const TermsAndCondition = React.lazy(() =>
  import('../assets/components/Global/PrivacyPolicy/TermsAndCondition.jsx').then(module => ({ default: module.TermsAndCondition }))
);

const About = React.lazy(() =>
    import('../pages/About/About').then(module => ({ default: module.About }))
);

const match = matchPath("/", {
    path: window.location.pathname,
    exact: true,
    strict: false,
})


//Resolved conflicts of indentation and new routes of ffiec
function getBrowserVersion() {
    let detectIEregexp
    if (navigator.userAgent.indexOf("MSIE") !== -1) {
        detectIEregexp = /MSIE (\d+\.\d+);/ //test for MSIE x.x
    } else {
        detectIEregexp = /Trident.*rv[ :]*(\d+\.\d+)/ //test for rv:x.x or rv x.x where Trident string exists
    } // if no "MSIE" string in userAgent

    if (detectIEregexp.test(navigator.userAgent)) {
        //if some form of IE
        let ieversion = RegExp.$1 // capture x.x portion and store as a number
        // if (ieversion>=11 && ieversion<12)
        return ieversion
        // else if (ieversion>=9 && ieversion<10)
        // return (ieversion);
    } else {
        return "Other"
    }
}

let browserResult = getBrowserVersion()

export const Unauthenticated = () => {

    let [isOnline, setIsOnline] = React.useState(true)
    let [userInfo, setUserInfo] = React.useState('')
    let [isMSPMultifactorAuth, setIsMSPMultifactorAuth] = React.useState(false);
    const location = useLocation();

  //   useEffect(() => {
  //       window.scroll(0, 0)
  //       let token = getItem('token');
  //   }, [])
  //
  //   useEffect(() => {
  //     let userInfo = getItem('userInfo');
  //     // setUserInfo(userInfo)
  //
  // }, [window.location.pathname])

    // useEffect(() => {
    //     let isOnline = window.navigator.onLine
    //     setIsOnline(isOnline)
    //     window.addEventListener("offline", () => {
    //         setIsOnline(false)
    //         return
    //     })
    //     window.addEventListener("online", () => {
    //         setIsOnline(true)
    //         // window.location.reload();
    //     })
    // }, [])

    const noSidebarPaths = ["/admin/login","/login","/sign-up", "/invite", "/set-new-password", "/set-password","/forgot-password","/", "/resources", '/privacyPolicy','/termsAndCondition', '/about']
    const navbarRoutes = ["/","/resources", '/privacyPolicy', '/termsAndCondition', '/about']
    /*
      Modified By: Akanksha Malviya
      Modified On: 30th Oct, 2023
      Modification: Added footer for landing and resources pages only
    */
    const navbar2Routes = ["/analysis", "/home", "/event", "/analysis/topicalHeatMap", "/analysis/seasonalSelection", "/analysis/marketPrioritization", "/analysis/corelatedCause", "/manageSlots",'/privacyPolicy','/termsAndCondition', '/about']
    const footerPaths = ["/","/resources", '/privacyPolicy','/termsAndCondition', '/about']
    const noFooterPaths = ["/admin/login","/login","/sign-up", "/invite", "/set-new-password", "/set-password","/forgot-password", "/admin/users", "/manageSlots", "/admin/sessions","/admin/reports", "/admin/contributions", "/event"]
    const protectedRoutes = ["/home", "/event", "/analysis/topicalHeatMap", "/analysis/seasonalSelection", "/analysis/marketPrioritization", "/analysis/corelatedCause", "/manageSlots", "/resources",
    "/admin",
    "/admin/manageSlots",
    "/admin/users",
    "/admin/sessions",
    "/admin/reports",
    "/admin/contributions",
    "/contribute-table", '/privacyPolicy','/termsAndCondition', '/about'];

    return (
        <>
            <ThemeProvider theme={TDTheme}>
                {!noSidebarPaths.includes(window.location.pathname) && browserResult === "Other" && isOnline && (
                    <Sidebar />
                )}

                {navbarRoutes.includes(window.location.pathname) && browserResult === "Other" && isOnline && (
                    <Navbar />
                )}



                {browserResult === "Other" && isOnline && (
                    <ContentWrapper>

                        <GlobalsStyle />
                        <Suspense fallback={<LoadingScreen/>}>

                        <Switch>
                            <Route exact path="/login" component={Login} />
                            <Route exact path="/admin/login" component={AdminLogin} />
                            <Route exact path="/sign-up" component={SignUp} />
                            <Route exact path="/forgot-password" component={ForgotPassword} />
                            <Route exact path="/set-new-password/:token?" component={SetNewPassword} />
                            <Route exact path="/set-password/:token?" component={SetPassword} />
                            <Route exact path="/" component={Landing} />
                            <Route exact path="/home" component={Home} />
                            <Route exact path="/login" component={Login} />
                            <Route exact path="/sign-up" component={SignUp} />
                            <Route exact path="/resources" component={Resources} />
                            <Route exact path="/privacyPolicy" component={PrivacyPolicy} />
                            <Route exact path="/termsAndCondition" component={TermsAndCondition} />
                            <Route exact path="/about" component={About} />

                            {
                              protectedRoutes.includes(location.pathname) &&
                              <Requireauth>
                                <Route exact path="/home" component={Landing} />
                              {/*  <Route exact path="/analysis" component={Analysis} />*/}
                                <Route exact path="/analysis/topicalHeatMap" component={TopicalHeatMap} />
                                <Route exact path="/analysis/seasonalSelection" component={SeasonalSelectionAnalysis} />
                                <Route exact path="/analysis/marketPrioritization" component={MarketPrioritizationAnalysis} />
                                <Route exact path="/analysis/corelatedCause" component={CorelatedCauseAnalysis} />
                                <Route exact path="/event" component={Event} />
                                <Route exact path="/manageSlots" component={ManageSlots} />
                                <Route exact path="/contribute-table" component={ContributeTable} />

                                <Route exact path="/admin/manageSlotsAdmin" component={ManageSlotsAdminDashboard} />
                                <Route exact path="/admin/users" component={UsersAdminDashboard} />
                                <Route exact path="/admin/sessions" component={SessionsAdminDashboard} />
                                <Route exact path="/admin/reports" component={ReportsAdminDashboard} />
                                {/*
                                  modified : Ashutosh G
                                  modified : 12/12/2023
                                  modification : admin/contributions route added for contribution table
                                */}
                                <Route exact path="/admin/contributions" component={ContributionAdminDashboard} />

                              </Requireauth>
                            }

                            <Route path="*" component={Error404} />

                        </Switch>
                        </Suspense>
                    </ContentWrapper>

                )}

                {/*
                  Date: 19th September, 2023
                  By: Akanksha Malviya
                  Modification: Added footer
                */}

                {footerPaths.includes(window.location.pathname) && browserResult === "Other" && isOnline && (
                    <Footer />
                )}
                {/* {browserResult !== "Other" && !isOnline && <UnsupportedBrowser />} */}
            </ThemeProvider>
        </>
    )
}
