import React, { useState, useEffect } from "react";
import { useLocation, Redirect, useHistory } from "react-router-dom";
import axios from "axios";
import { AppStore } from '../lib/store';
import CONSTANTS from "../lib/constants";
import { LoadingScreen } from "../assets/components/CommonComponents";
import Cookies from 'js-cookie';
import { removeLinkedinCookies } from '../lib/cookies';

/*
  modified : Ashutosh G
  modified : 17/07/2023
  modification : component added to check user is logined before proceding to protected route
*/
const RequireAuth = ({ children }) => {
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const history = useHistory();

  useEffect(() => {
    checkUserIsLogin();
  }, []);

  function checkUserIsLogin() {
    const cookieValue = Cookies.get('linkdin-token');
    if(cookieValue){
      return axios
        .get(`${CONSTANTS.API_URL}/api/auth/getInfoByLinkdinToken?token=${cookieValue}`)
        .then(() => {
          setIsAuthenticated(true);
        })
        .catch((HTTPException) => {
          setIsAuthenticated(false);
          console.log("invalid");
          // Cookies.remove('linkdin-token');
      });
      setIsAuthenticated(false);
    }

    if (AppStore.get('token') && Object.keys(AppStore.get('token')).length) {
      axios.get(`${CONSTANTS.API_URL}/api/v1/validateSessionByToken/${AppStore.get('token').token}`)
        .then(() => {
          setIsAuthenticated(true);
        })
        .catch((HTTPException) => {
          console.log("invalid");
          console.error(HTTPException);
          AppStore.removeAll();
          /*
            modified : Ashutosh G
            modified : 10/11/2023
            modification : removing linkdinToken and token from cookie after clearing localstorage
          */
          removeLinkedinCookies();
          setIsAuthenticated(false);
        });
    } else {
      setIsAuthenticated(false);
    }
  }

  if (isAuthenticated === null) {
    // Still loading, show a loading indicator or skeleton screen
    return <LoadingScreen/>;
  } else if (isAuthenticated) {
    return children;
  } else {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location },
        }}
      />
    );
  }
};

export default RequireAuth;
