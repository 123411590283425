import React, { useState, useEffect } from "react"
import { getEncryptedData, getDecryptedData } from "../../../lib/encryption"
import { useHistory } from "react-router-dom"
import { Link, useLocation } from "react-router-dom"

import {
    Element,
    Row,
    Portion,
    FormWrapper,
    InputField,
    Button,
    Card,
    Heading,
    Text,
    HRule,
    FormItem,
    NotificationItem,
    NotificationsWrapper,
} from "fictoan-react"

import { LoginStyled } from "./Login.styled"
import { withPageDetails } from "../../../assets/helpers/Page"
import qs from "../../../assets/helpers/qs"

import axios from "axios"
import CONSTANTS from "../../../lib/constants"
import ReCaptcha from "../../../lib/captcha"
import { setItem, getItem, removeAll } from "../../../lib/myStore"
import LogoApp from "../../../assets/images/Logo1.png"
import EyeOpen from "../../../assets/icons/eye.svg"
import EyeClose from "../../../assets/icons/eye-off.svg"
import moment from "moment"
import { AppStore } from '../../../lib/store';
import { PurposeInfo } from "../../../assets/components/CommonComponents";
import Cookies from 'js-cookie';
import { LoadingScreen } from "../../../assets/components/CommonComponents";
import { removeLinkedinCookies } from '../../../lib/cookies';

let distance = 60000, distanceFailedAttempts = 60000 ,distanceForgot = 60000, distanceVerification = 29000;


const Page = () => {
    let history = useHistory()
    const [values, setValues] = useState({
        email: "",
        password: "",
    })
    const [isCreateAccount, setIsCreateAccount] = useState(false)
    const [isLoginError, setIsLoginError] = useState(false)
    const [resetLinkMessage, setResetLinkSuccessMsg] = useState(false)
    const [resetLinkErrorMsg, setResetLinkErrorMsg] = useState(false)
    const [isInvalidCrediantials, setIsInvalidCrediantials] = useState(false)
    const [isLoginErrorMsg, setIsLoginErrorMsg] = useState("")
    const [isEmailInvalidMsg, setIsEmailInvalidMsg] = useState("")
    const [isEmailValid, setIsEmailValid] = useState(false)
    const [isValid2FAPin, setIsValid2FAPin] = useState(true)
    const [passwordErrorMsg, setPasswordErrorMsg] = useState("")
    const [isCaptchResponse, setIsCaptchResponse] = useState(false)
    const [captchaResponse, setCaptchaResponse] = useState("")
    const regex = /^((([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))|([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/
    const mfaRegex = /^[0-9]{6}$/
    const location = useLocation()
    const { out } = qs(location.search)
    let [notification, setNotification] = useState({
        visible: false,
        message: "",
        type: "success",
    })
    let [token, setToken] = useState(getItem("token"))

    let [pin, set2FAPin] = useState(false)
    let [loginResponse, setLoginResponse] = useState(false)
    let [isSmsAuthenticator, setIsSmsAuthenticator] = useState(false)
    let [isGoogleAuthenticator, setIsGoogleAuthenticator] = useState(false)
    let [isDuoMobile, setIsduoMobile] = useState(false)

    const [resendActivated, setResendActivated] = useState(false);

    const [errorMessage, setErrorMessage] = React.useState('');
    const [isErrorShown, setErrorShown] = React.useState(false);

    const [isAttemptsOver, setIsAttemptsOver] = useState(false);
    const [userLocked, setUserLocked] = useState(false);
    const [isPasswordIncorrect, setIsPasswordIncorrect] = useState(false);
    const [isPasswordEmpty, setIsPasswordEmpty] = useState(false);
    const [isEmailEmpty, setIsEmailEmpty] = useState(false);
    const [isLoginButtonDisabled, setIsLoginButtonDisabled] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [userID, setUserID] = useState('');
    const [invalidAttempts, setInvalidAttempts] = useState(0);
    const [visibleForm, setVisibleForm] = useState('login');
    const [userName, setUserName] = useState('');
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isVerificationLinkSent, setIsVerificationLinkSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    //for remembering from where redirected to login page
    const fromPath = location?.state?.from?.pathname || "/analysis/topicalHeatMap";

  /*
  modified : Ashutosh G
  modified : 17/07/2023
  modification : below code check is user logged in either with normal or linkedin login and redirect to requested route if logged in
  */
  /*
    modified : Ashutosh G
    modified : 01/11/2023
    modification : cookie token saved in localstorage for api authorization
  */
    function checkUserIsLogin() {
      setIsLoading(true);
      const cookieValue = Cookies.get('linkdin-token');
      const token = Cookies.get('token');
      console.log(cookieValue,"cookieValuecookieValuecookieValue");
      console.log(token,"tokentokentoken");
      if(cookieValue){
        return axios
          .get(`${CONSTANTS.API_URL}/api/auth/getInfoByLinkdinToken?token=${cookieValue}`)
          .then(async () => {
            let redirectedTo = (!fromPath || fromPath== "/") ? "home" : fromPath;
            setItem("token", {
              token: token
            });

            /*
              modified : Ashutosh G
              modified : 21/11/2023
              modification : saved user info in localstorage on linkedin login
            */
            let userinfo = await axios.get(`${CONSTANTS.API_URL}/api/user/me`);
            let userData = userinfo.data;

            setItem('userinfo', userData);
            history.push(redirectedTo);
          })
          .catch((HTTPException) => {
            console.log("invalid");
            /*
              modified : Ashutosh G
              modified : 10/11/2023
              modification : removing linkdinToken and token from cookie
            */
            removeLinkedinCookies();
        });
      }

      if (AppStore.get('token') && Object.keys(AppStore.get('token')).length){
        return axios
          .get(`${CONSTANTS.API_URL}/api/v1/validateSessionByToken/${AppStore.get('token').token}`)
          .then(() => {
            history.push('/')
          })
          .catch((HTTPException) => {
            console.log("invalid");
            // new Toast('Session Expired.', Toast.TYPE_ERROR, Toast.TIME_LONG);
            console.error(HTTPException);
            // stopLoader();
            AppStore.removeAll();
            /*
              modified : Ashutosh G
              modified : 10/11/2023
              modification : removing linkdinToken and token from cookie after clearing localstorage
            */
            removeLinkedinCookies();
            // return history.push('/');
          });
      }
      setIsLoading(false);
    }

    useEffect(() => {
       checkUserIsLogin();
     }, []);

    useEffect(() => {
        if (out) {history.push("/sign-up")
            setNotification({
                ...notification,
                visible: true,
                message: "Logged out.",
                type: "success",
            })
            setTimeout(() => {
                setNotification({
                    visible: false,
                })
            }, 4000)
        }
    }, [])

    const handleChange = (e) => {
        e.persist()
        setValues((values) => ({
            ...values,
            [e.target.name]: e.target.value,
        }))
    }
  /*
  modified : Ashutosh G
  modified : 17/07/2023
  modification : below code is to check user is valid or not
  */
    function validateEmailCriterion(e) {
        e.preventDefault()
        if (e.target.value){
            setIsEmailValid(regex.test(e.target.value))
            if (regex.test(e.target.value)) {
                setIsEmailInvalidMsg("")
            } else {
                setIsEmailInvalidMsg("This is not a valid Email/Mobile Number")
            }
        } else {
            setIsEmailValid(true)
            setIsEmailInvalidMsg("")
        }
    }

    function validatePassword(e) {
        e.preventDefault()
        if (e.target.value) {
            setPasswordErrorMsg("")
        } else {
            setPasswordErrorMsg("Please Enter Password")
        }
    }

    async function login(e) {
      setVisibleForm('login');

      e.preventDefault();
      if (isAttemptsOver || userLocked) {
        return;
      }
      setIsAttemptsOver(false);
      setIsPasswordIncorrect(false);
      setIsPasswordEmpty(false);
      // if (!e.target.uniquePassword.value) {
      //   setIsPasswordEmpty(true);
      // }

      // if (!e.target.email.value) {
      //   setIsEmailEmpty(true);
      // }
      setIsLoginButtonDisabled(true);
      try {
        let response = await axios.post(`${CONSTANTS.API_URL}/api/v1/login`, {
          username: values.email,
          password: values.password
        });

        setToken(response.data.token);
        setUserID(response.data.user.uuid);
        setIsLoginButtonDisabled(false);
        if (response.data.statusCode === 700) {
          setInvalidAttempts(response.data.user.loginAttempts);
          setIsPasswordIncorrect(true);
        } else if (response.data.user.googleAuthenticator) {
          setIsGoogleAuthenticator(true);
        } else {
          setItem('token', {
            token: response.data.token
          });
          let userinfo = await axios.get(`${CONSTANTS.API_URL}/api/user/me`);
          let userData = userinfo.data;

          setItem('userinfo', userData);

          let redirectedTo = (!fromPath || fromPath== "/") ? "/" : fromPath;
          history.push(redirectedTo);

        }
      } catch (HTTPException) {
        setIsLoginButtonDisabled(true);

        if (HTTPException.response.data.statusCode === 700) {
          setInvalidAttempts(0);
          setIsPasswordIncorrect(true);
        }
        if (HTTPException.response.data.statusCode === 702 && HTTPException.response.data.error === 'Unauthorized') {
          let response = await axios.post(`${CONSTANTS.API_URL}/api/v1/verifyUser`, {
            userEmail: values.email,
            typeOfEmail: 'verificationEmail'
          });

          setVisibleForm('userNotVerified');
          if (response && response.data && response.data.length) {
            setUserName(response.data[0].displayName);
          }
        }
        if (HTTPException.response.data.statusCode === 703) {
          setIsAttemptsOver(true);

          let x = setInterval(() => {
            distance = distance - 1000;
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);
            if (seconds < 10) {
              document.getElementById("time").innerHTML = "0" + seconds;
            }
            else {
              document.getElementById("time").innerHTML = seconds;
            }

            if (distance < 0) {
              document.getElementById("time").innerHTML = "60";
              clearInterval(x);
              distance = 60000;
              setIsAttemptsOver(false);
              setIsLoginButtonDisabled(true);
              setUserLocked(false);
            }
          }, 1000);
        }
      }
      return;
    }

    function togglePasswordVisibility() {
      setIsPasswordVisible(!isPasswordVisible);
    }

    async function requestForVerificationLink() {
      try {
        await axios.put(`${CONSTANTS.API_URL}/api/v1/resendLink/${values.email}`
        ).then(() => {
          setIsVerificationLinkSent(true);
          let x = setInterval(() => {
            distanceVerification = distanceVerification - 1000;
            let seconds = Math.floor((distanceVerification % (1000 * 60)) / 1000);
            if (seconds < 10) {
              document.getElementById("time-verification").innerHTML = "0" + seconds;
            }
            else {
              document.getElementById("time-verification").innerHTML = seconds;
            }
            if (distanceVerification < 0) {
              document.getElementById("time-verification").innerHTML = "29";
              clearInterval(x);
              distanceVerification = 29000;
              setIsVerificationLinkSent(false);
            }
          }, 1000);
        }).catch(error => {
          if (error.response && error.response.data) {
            console.log(error.response.data.message);
          } else {
            console.log('Something Went Wrong');
          }
        });
      }
      catch (HTTPException) {
        console.error(HTTPException);
        return false;
      }
    }

    {/*
      Bug: TT_BG_78 (When clicking on the TrendTonik icon, the website is not rendering to the home page.)
      Date: 29th September, 2023
      By: Akanksha Malviya
      Modification: Created a function to redirect to home Page
    */}
    function handleClickLogo() {
      history.push("/");
    }

    return (
        isLoading
          ? <LoadingScreen />
          :
        <LoginStyled>
            <Row sidePadding="tiny" paddingTop="tiny">
              <Portion desktopSpan="4" mobileSpan="24">
                  <img src={LogoApp} alt="LogoApp" style={{ cursor:'pointer' }} onClick={handleClickLogo} onContextMenu={(e) => { e.preventDefault(); }} draggable="false" />
              </Portion>
            </Row>

            <Row sidePadding="small">
                <Portion desktopSpan="15" hideOnTabletPortrait hideOnMobile>
                  <PurposeInfo />
                </Portion>

                <Portion desktopSpan="8" paddingTop="nano">
                    <Card padding="medium" shape="rounded" bgColor="white" shadow="soft">
                        <FormWrapper>
                            <Heading as="h6" marginBottom="tiny">
                                Login
                            </Heading>
                            {/*
                              modified : Ashutosh G
                              modified : 08/11/2023
                              modification : simple login from commented
                            */}
                            {/*<InputField
                                required
                                //validateThis
                                // pattern='^((([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))|([0|\+[0-9]{1,5})?([7-9][0-9]{9})$}'
                                // type="email"
                                autoFocus
                                label="Email or Mobile"
                                errorText="Must be a valid email"
                                name="email"
                                onChange={(e) => {
                                    handleChange(e)
                                }}
                                onBlur={(e) => {
                                    validateEmailCriterion(e)
                                }}
                            />
                            {isEmailInvalidMsg && (
                                <p className="text-red">
                                   {isEmailInvalidMsg}
                                </p>
                            )}

                            <div style={{ position:"relative" }}>
                              <InputField
                                  required
                                  type={isPasswordVisible ? "text" : "password"}
                                  label="Password"
                                  errorText="Password seems wrong"
                                  //validateThis
                                  name="password"
                                  onChange={(e) => {
                                      handleChange(e)
                                  }}
                                  onBlur={(e) => {
                                      validatePassword(e)
                                  }}
                                  disabled={isSmsAuthenticator || isGoogleAuthenticator || isDuoMobile}
                                  style={{ paddingRight: "35px" }}
                                  marginBottom="none"
                              />
                              <button
                                type="button"
                                onClick={togglePasswordVisibility}
                                className="eye-symbol"
                              >
                                {isPasswordVisible ? <img src={EyeOpen} alt="EyeClose" /> : <img src={EyeClose} alt="EyeClose" />}
                              </button>
                            </div>

                            {passwordErrorMsg && (
                                <p className="text-red">
                                   {passwordErrorMsg}
                                </p>
                            )}

                            <div className="button-block m-b-20">
                              {
                                isPasswordIncorrect && (
                                  <p className="text-red">
                                    Incorrect Credentials
                                  </p>
                                )

                              }
                              {
                                (isPasswordIncorrect && invalidAttempts != 0) && (
                                  <p className="text-red">
                                    Attempt {invalidAttempts} of 6
                                  </p>
                                )
                              }
                              {
                                isEmailEmpty && (
                                  <p className="text-red">
                                    Please enter your Email / Mobile
                                  </p>
                                )
                              }
                              {
                                isPasswordEmpty && (
                                  <p className="text-red">
                                    Please enter your password.
                                  </p>
                                )
                              }
                              {
                                isAttemptsOver && (
                                  <p className="text-red">
                                    Oops! Too many incorrect credentials attempts.
                                    Please wait for 00:<span id="time">60</span> secs and retry
                                  </p>
                                )
                              }
                              {
                                userLocked && (
                                  <p className="text-red">
                                    Oops! Too many incorrect credentials attempts. Please wait for 00:<span id="time-failed">{parseInt(distanceFailedAttempts.toString().substring(0, 2), 0)}</span> secs and retry
                                  </p>
                                )
                              }
                            </div>
                            {
                              visibleForm === 'userNotVerified' && (
                                <div className="login-form min-h-72vh" style={{ zIndex: 2}}>
                                  <div className="login-row">
                                    <label>
                                      Hi {userName},<br />
                                      We have sent you a verification link.<br/>
                                    </label>
                                    <span>
                                      Please check your email for the verification link we sent you.
                                    </span>
                                  </div>
                                  <div className="button-block">
                                    <p className="question">
                                      Didn’t receive a verification email?
                                    </p>
                                    {
                                      !isVerificationLinkSent && (
                                        <label className="resent-link" onClick={(e) => requestForVerificationLink(e)} style={{width: "75px"}}>
                                          Resend link
                                        </label>
                                      )
                                    }
                                    {
                                      isVerificationLinkSent && (
                                        <p className="error-message no-weight fs-12 f-w-300">
                                          Please wait 00:<span id="time-verification">29</span> secs to resend<br />another verification email
                                        </p>
                                      )
                                    }
                                  </div>
                                </div>
                              )
                            }

                            <div className="push-to-ends vertically-center-items margin-top-tiny">
                                <span>
                                    {(!isSmsAuthenticator && !isGoogleAuthenticator && !isDuoMobile) && (
                                        <Button
                                            type="submit"
                                            shape="rounded"
                                            shadow="mild"
                                            bgColor="black"
                                            style={{border:"1px solid #ffb400", color:'#ffb400'}}
                                            onClick={login}
                                            disabled={!values.email || !values.password || isEmailInvalidMsg}
                                        >
                                            LOGIN
                                        </Button>
                                    )}
                                </span>
                                <Link disabled to="/forgot-password" className="weight-400" style={{color:"#ffb400"}}>
                                    Forgot Password?
                                </Link>
                            </div>

                            <Text style={{ margin: "10px 0", textAlign: "center"  }}>or</Text>*/}

  {/*
  modified : Ashutosh G
  modified : 17/07/2023
  modification : linkedin button added
  */}
                            <div className="social-logins">
                              <a href={`${CONSTANTS.API_URL}/api/auth/linkedin`} title="LinkedIn" className="btn btn-linkedin btn-lg">
                                <i className="fa fa-linkedin linkedin-icon"></i>
                                Sign in with Linkedin
                              </a>
                            </div>

                        </FormWrapper>
  {/*
  Modified By: Akanksha Malviya
  Modified On: 25th july 2023
  Modification: styled text accross to maintain uniformity
  */}
                            <Text size='small' marginTop='micro' weight="400">
                                First Time Here?{`\u00A0`}
                                <Link disabled to="/sign-up" style={{color:"#ffb400"}}>Create an account</Link>
                            </Text>
                    </Card>
                </Portion>
                <Portion desktopSpan="9" />
            </Row>
            {notification.visible && (
                <NotificationsWrapper position="left" anchor="bottom">
                    <NotificationItem type={notification.type || "success"}>{notification.message}</NotificationItem>
                </NotificationsWrapper>
            )}
        </LoginStyled>
    )
}

export const Login = withPageDetails(Page, {
  pageTitle: "Login — TrendTonik",
})
