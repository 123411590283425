const CONSTANTS = {
  /*localHost api url*/
   //API_URL: 'http://localhost:5000',
   // API_URL: 'http://192.168.1.47:5000',
    API_URL: 'https://trendtonik.com',
 // DOMAIN: "localhost",
  DOMAIN: ".trendtonik.com",
  /*
    modified : Ashutosh G
    modified : 11/12/2023
    modification : everyOrg apikey, url and webhook_token added
  */
  everyOrg: {
    apiKey: "pk_live_5615b75ec8868ecdcfee00ebca6ee75d",
    webhook_token : "E2TBS0CMGui1S05vLZGf48lY",
    // url: "https://staging.every.org/", // test url
    url: "https://www.every.org/",  // live url
  },
  SITEKEYFORCAPTCHA: '6Lcqb-gUAAAAAOGVHdw0MutHwAlPuLfo8UCZkTe3',
};

export default CONSTANTS;
