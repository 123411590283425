import React,{useState,useEffect, useRef} from "react";
import {NavLink, useHistory, useLocation} from "react-router-dom";
import {
  Row,
  Portion,
  Text,
  Button,
  Select,
  Element,
  InfoPanel
} from "fictoan-react"
import { getItem, removeAll } from '../../../../lib/myStore';

import UserIcon from "../../../images/user.png";
import Logo from "../../../images/Logo2.png";
import axios from "axios";
import CONSTANTS from "../../../../lib/constants";
import Cookies from 'js-cookie';
import {Dropdown} from "../../GlobalDropdown/Dropdown";
import {NavbarStyled} from "./NavbarV2.styled";
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import {useOnClickOutside} from "../../AnalysisComponents/useOnClickOutside";
import Menu from "../../../images/menu.png";
import { removeLinkedinCookies } from '../../../../lib/cookies';

export const NavbarV2 = () => {

  const [isLogin, setIsLogin] = useState(false);
  const [showList, setShowList] = useState(false);
  const [showResources, setShowResources] = useState(false)
  const [showSolutions, setShowSolutions] = useState(false)
  const location = useLocation();
  const [pathName, setPathName] = useState("")
  const [isSampleInfoPanelOpen, setIsSampleInfoPanelOpen] = useState(false);

  /*
    Date: 19th September, 2023
    By: Akanksha Malviya
    Modification: Added options for Solutions and Resources dropdown
  */
  const solutionsOptions = [

    {
        name: "Topical Heatmap",
        value: "TopicalHeatmap"
    },
    {
        name: "Seasonal Selection",
        value: "SeasonalSelection"
    },
    {
        name: "Priority Markets",
        value: "MarketPrioritization"
    },
    {
        name: "Correlated Causes",
        value: "CorelatedCause"
    }
  ]

  const resourcesOptions = [
      {
          name: "Learn the ropes",
          value: "learnRopes",
          to: "learnRopes",
      },
      {
          name: "Cool Projects",
          value: "coolProjects",
          to: "coolProjects"
      },
      {
          name: "Let's Play",
          value: "play",
          to: "play"
      },
  ]

  const [selected, setIsSelected] = useState("")
  const ref = useRef()
  const ref2 = useRef()
  const ref3 = useRef()
  const ref4 = useRef()
  const [width, setWidth] = useState("48%")

  const handleDropdownChange = (newValue) => {
    setIsSelected(newValue)
    setWidth("8%")
  };

  const [isActive, setIsActive] = useState(false);
  /*
  Date: 23rd September,
  By: Akansha Malviya
  Modification: added onclick outside function for dropdown
  */
  useOnClickOutside(ref,()=>{setIsActive(false)})
  useOnClickOutside(ref2,()=>{setShowList(false)})
  useOnClickOutside(ref3,()=>{setShowResources(false)})
  useOnClickOutside(ref4,()=>{setShowSolutions(false)})

  function checkIsLogin() {
    let token = getItem("token") ? getItem("token").token : false;
    let linkdinToken = Cookies.get("linkdin-token");
    if (token || linkdinToken) {
      setIsLogin(true)
    } else {
      setIsLogin(false)
    }
  }
  useEffect(()=>{
    setPathName(location.pathname)
    checkIsLogin();
  }, [location]);

  const history = useHistory();
  function handleClickLogo() {
    history.push("/");
    setIsSampleInfoPanelOpen(false)
  }

  const logout = async (e) => {
    e.preventDefault();
    console.log('logout');

    let token = getItem("token") ? getItem("token").token : false;
    if(token){
      let res = await axios.post(`${CONSTANTS.API_URL}/api/v1/logout`);
      // let email = getItem('userinfo').email;
      // await axios.put(`${CONSTANTS.API_URL}/api/v1/user/pageVisitUserInformation`, { action: 'logout', typeOfVisit: 'success', email });
      if (res) {
        removeAll();
        /*
          modified : Ashutosh G
          modified : 10/11/2023
          modification : removing linkdinToken and token from cookie after clearing localstorage
        */
        removeLinkedinCookies();
        // window.location.href = "/"
        history.push("/")
        // history.replace('/');
      }
      setShowList(!showList)
    } else{
      history.push("/");
    }

    checkIsLogin();
  };

  {/*
    Date: 21st September, 2023
    By: Akanksha Malviya
    Modification: Added scroll function to scroll to top
  */}
  const scrollToTop = () => {
    scroll.scrollToTop(); // Scroll to the top of the page
  };

  const isSmallScreen = window.innerWidth <= 640;

  let drawerClasses = 'side-drawer';

  if (isSampleInfoPanelOpen) {
    drawerClasses = 'side-drawer open';
  }

  return (

    <NavbarStyled>
       <Row className="push-to-ends" sidePadding="micro" marginBottom="none" style={{height:'57px'}} >

          <Portion marginTop="none" marginBottom="nano" desktopSpan="24" mobileSpan="24" style={{borderBottom:"1px solid lightgrey"}}>
              <Element as="div" className="push-to-ends vertically-center-items">

                  <Element marginTop="nano" as="div" showOnlyOnMobile={true} onClick={() => setIsSampleInfoPanelOpen(true)}>
                    <img src={Menu} style={{width:'20px', height:'20px'}} onClick={()=>history.push("/")} />
                  </Element>

                  {
                    isSampleInfoPanelOpen && (
                      <div className={drawerClasses}>

                        <Element as="div" style={{display:'flex', justifyContent:'space-between'}}>
                          <Element as="div" onClick={()=> setIsSampleInfoPanelOpen(false)}>
                            <Text size="large"> &#10006; </Text>
                          </Element>

                          <Element as="div">
                            <Text marginTop="nano" onClick={handleClickLogo} className="cursor pointer navbarText"> Trend<span style={{color:'#ffc314'}}>Tonik</span></Text>
                          </Element>
                        </Element>

                        <Element as="div" marginTop="micro" className="justifyBetween">
                          <Text weight="600" size="large" className="cursor pointer" onClick={()=>{
                            history.push("/resources");
                            setIsSampleInfoPanelOpen(false)
                          }}> Resources</Text>
                          <Text weight="600" size="large" className="cursor pointer" onClick={()=>{
                            history.push("/resources");
                            setIsSampleInfoPanelOpen(false);
                          }}> &gt; </Text>
                        </Element>

                        <Element as="div" marginTop="micro" className="justifyBetween">
                          <Text weight="600" size="large" className="cursor pointer"> Contribute </Text>
                          <Text weight="600" size="large" className="cursor pointer"> &gt; </Text>
                        </Element>


                      </div>
                    )
                  }

                  <div>

                  </div>
                  <div className="flex-c-m" >

                  {/*
                    Date: 19th September, 2023
                    By: Akanksha Malviya
                    Modification: Custom Dropdown created
                  */}
                    {/*<Dropdown options ={solutionsOptions} text="Solutions" showOnlyOnDesktop={true} />
                    <Select className="after-select" options={resourcesOptions} />*/}
                    {/*
                      Date: 21st September, 2023
                      By: Akanksha Malviya
                      Modification: Added dropdown for Resouces for smooth scrolling
                    */}
                    {/*
                      Date: 27th September, 2023
                      By: AKanksha Malviya
                      Modification: Removed condition for pathName , becase home and Landing page merged
                    */}
                      <Text className="cursor pointer" showOnlyOnDesktop={true} onClick={()=>history.push("/")}> Home </Text>
                      <div className="dropdown-container" ref={ref4}>
                        <Element as="div" marginTop={isSmallScreen ? "nano":"none"}
                         onClick={()=>setShowSolutions(!showSolutions)}
                        >
                          <Text className="cursor pointer" showOnlyOnDesktop={true}> Solutions </Text>
                        </Element>
                        {
                          showSolutions && (
                            <div className="dropdown-menu" style={{width:"170px"}}>
                              <li style={{padding:'1px'}} onClick={()=>{
                                history.push("/analysis/topicalHeatMap")
                                setShowSolutions(!showSolutions)
                              }}> Topical HeatMap
                              </li>
                              <li style={{padding:'1px'}} onClick={()=>{
                                history.push("/analysis/seasonalSelection")
                                setShowSolutions(!showSolutions)
                              }}> Seasonal Selection
                              </li>
                              <li style={{padding:'1px'}} onClick={()=>{
                                history.push("/analysis/marketPrioritization")
                                setShowSolutions(!showSolutions)
                              }}> Priority Markets
                              </li>
                              <li style={{padding:'1px'}} onClick={()=>{
                                history.push("/analysis/correlatedCauses")
                                setShowSolutions(!showSolutions)
                              }}> Correlated Causes
                              </li>
                            </div>
                          )
                        }
                      </div>
                      <div className="dropdown-container" ref={ref3}>
                        <Element as="div" marginTop={isSmallScreen ? "nano":"none"}
                         onClick={()=>setShowResources(!showResources)}
                        >
                          <Text className="cursor pointer" showOnlyOnDesktop={true}> Resources </Text>
                        </Element>
                        {
                          showResources && (
                            <div className="dropdown-menu" style={{width:"170px"}}>
                              <li style={{padding:'1px'}}>
                              <ScrollLink to="learnRopes" smooth={true} duration={500} onClick={()=>{
                                history.push("/resources")
                                setShowResources(!showResources)
                              }}>Learn the ropes</ScrollLink>
                              </li>
                              <li style={{padding:'1px'}}>
                              <ScrollLink to="coolProjects" smooth={true} duration={500} onClick={()=>{
                                history.push("/resources")
                                setShowResources(!showResources)
                              }}>Cool Projects</ScrollLink>
                              </li>
                              <li style={{padding:'1px'}}>
                              <ScrollLink to="play" smooth={true} duration={500} onClick={()=>{
                                history.push("/resources")
                                setShowResources(!showResources)
                              }}>Let's Play</ScrollLink>
                              </li>
                            </div>
                          )
                        }
                      </div>
                      {/*<Text className="cursor pointer" showOnlyOnDesktop={true}> Contribute </Text>

                        Date: 23rd September, 2023
                        By: Akanksha Malviya
                        Modification : created dropdown for login and register
                      */}
                      {/*
                        Bug: TT_BG_77 (Handpointer is missing on the User icon)
                        Date: 29th September, 2023
                        By: Akanksha Malviya
                        Modification: Added cursor to user icon
                      */}
                     {
                        !isLogin ?
                        <div className="dropdown-container" ref={ref}>
                          <Element as="div" marginTop={isSmallScreen ? "nano":"none"} className="cursor pointer" onClick={()=>setShowList(!showList)}>
                            <Button className="login-btn" >Login</Button>
                          </Element>
                          {
                            showList && (
                              <div className="dropdown-menu">
                                <div onClick={()=>{ history.push("/login"); }}>Login</div>
                                <div onClick={()=>{ history.push("/sign-up"); }}>Register</div>
                              </div>
                            )
                          }
                        </div>
                       :
                         <>
                          {/*
                            Date: 21st September, 2023
                            By: Akansha Malviya
                            Modification: Added dropdown to profile
                          */}
                          {/*
                            Bug: TT_BG_77 (Handpointer is missing on the User icon)
                            Date: 29th September, 2023
                            By: Akanksha Malviya
                            Modification: Added cursor to user icon
                          */}
                           <div className="dropdown-container" ref={ref2}>
                             <Element as="div" marginTop={isSmallScreen ? "nano":"none"} className="user-icon"
                              onClick={()=>setShowList(!showList)}
                             >
                                <span><img src={UserIcon} style={{height:'20px', width:'20px'}} /></span>
                             </Element>
                             {/*
                               Bug: TT_BG_88 (The dropdown window is not getting dissapeard after clicking on it)
                               Date: 29th September, 2023
                               By: Akanksha Malviya
                               Modification: Prevented dropdown to show after click
                             */}
                             {
                               showList && (
                                 <div className="dropdown-menu">
                                   <div onClick={()=>setShowList(!showList)}><a target='_blank' href={`https://docs.google.com/forms/d/e/1FAIpQLScn6QAImjxzVMmsg3CbeP0bZDHX56peIYDwBpFQUm8FZI_oCQ/viewform?usp=sf_link`}>Feedback</a></div>
                                   <div onClick={()=>{ history.push("/event"); setShowList(!showList); }}>Catch-up</div>
                                   <div>Contribute</div>
                                   <div onClick={logout}>Logout</div>
                                 </div>
                               )
                             }
                           </div>
                         </>
                     }

                  </div>
              </Element>
          </Portion>

       </Row>
    </NavbarStyled>
  )
}
