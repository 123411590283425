import styled from "styled-components"

import { defaultColours as baseColors } from "fictoan-react"

export const LoginStyled = styled.article`
    background: #fff;
	  background-size: 400% 400%;

    #resend-code {
        margin-top: -24px;
    }

    /*
      modified : Ashutosh G
      modified : 17/07/2023
      modification : below css code is for reset link and linkedin button.
    */
    .resent-link{
      color: blue;
      cursor: pointer;
    }

    .btn-linkedin {
  	  background: #0E76A8;
  	  border-radius: 0;
  	  color: #fff;
  	  border-width: 1px;
  	  border-style: solid;
  	  border-color: #084461;
      padding: 10px;
      width: fit-content;
      border-radius: 3px;
      display: flex;
      gap: 10px;
      align-items: center;
      padding: 7px 10px;
      text-transform: uppercase;
  	}

   .btn-linkedin:link, .btn-linkedin:visited {
	    color: #fff;
    }

  	.btn-linkedin:active, .btn-linkedin:hover {
  	  background: #084461;
  	  color: #fff;
  	}

    .linkedin-icon {
      padding: 5px;
      font-size: 15px;
      background: #fff;
      color: #0E76A8;
      border-radius: 5px
    }

    .social-logins{
      margin-top: 10px;
    }
`
