/*
  Date: 19th September, 2023
  By: Akanksha Malviya
  Modification: Global Footer created
*/

import React, {useState} from "react";
import {NavLink, useHistory} from "react-router-dom";
import {
  Row,
  Portion,
  Text,
  Button,
  Select
} from "fictoan-react"
import {FooterStyled} from "./Footer.styled";
import { FilePreviewer } from "../FilePreviewer";
import { ContributeModal } from "../../CommonComponents";
import { getItem } from "../../../../lib/myStore";

/*
  modified : Ashutosh G
  modified : 12/10/2023
  modification : Code added to open/close Contribute Modal
*/
export const Footer = () => {
const history = useHistory();
const [filePreview, setFilePreview] = useState({
    loading: false,
    forceClosed: true
});

function toggleContributeModal() {
  if (Object.keys(getItem("token")).length > 0) {
      setFilePreview(prevValue => { return { ...prevValue, forceClosed: !prevValue.forceClosed } });
  } else {
    history.push("/login");
  }

}
  return (
    <>
      <FooterStyled>
        <Row marginBottom="none">
          <Portion desktopSpan="24" mobileSpan="24" tabletPortraitSpan="24" style={{backgroundColor:"#212b2d"}}>
            <Row sidePadding="huge" marginTop="micro" marginBottom="nano" retainLayoutOnTabPT>
              {/*
                Date: 25th September, 2023
                By: Akanksha Malviya
                Modification: Added class for responsiveness
                */}
              <Portion desktopSpan ="8" mobileSpan="8" tabletPortraitSpan="8" >
                <Text align="center" textColor="white" className="text1" style={{fontWeight:'bold',cursor: 'pointer'}} > <a style={{color: '#ffc314'}} target='_blank' href={`https://docs.google.com/forms/d/e/1FAIpQLScn6QAImjxzVMmsg3CbeP0bZDHX56peIYDwBpFQUm8FZI_oCQ/viewform?usp=sf_link`}>FEEDBACK IS A GIFT </a></Text>
                <Text align="center" textColor="white" size="small" className="text2">Takes 1 Minute</Text>
                <hr className="dash"></hr>
              </Portion>
              <Portion desktopSpan ="8" mobileSpan="8" tabletPortraitSpan="8" >
                <Text align="center" className="text1" style={{cursor: 'pointer', fontWeight:'bold', color:'#ffc314'}} onClick={()=>{ history.push("/event"); }}>SLOT A CATCHUP</Text>
                <Text align="center" textColor="white" size="small" className="text2">Let's Get Introduced</Text>
                <hr className="dash"></hr>
              </Portion>
              <Portion desktopSpan ="8" mobileSpan="8">
                <Text align="center" textColor="white" className="text1" style={{fontWeight:'bold', cursor: "pointer", color:'#ffc314'}} onClick={toggleContributeModal}>CONTRIBUTE CHARITY</Text>
                <Text align="center" textColor="white" size="small" className="text2">Find Your Cause</Text>
                <hr className="dash"></hr>
              </Portion>
            </Row>
          </Portion>
        </Row>
        <Row marginBottom="none" style={{backgroundColor:"black"}} retainLayoutOnTabPT>
        <Portion desktopSpan="12" tabletLandscapeSpan='12' tabletPortraitSpan='12' mobileSpan="10" marginTop="nano" marginBottom="nano" >
          <Text paddingLeft='nano' align="left" style={{color:"#777777"}}><span onClick={()=>{ history.push("/termsAndCondition"); }} style={{cursor: 'pointer'}}>Terms </span> and <span onClick={()=>{ history.push("/privacyPolicy"); }} style={{cursor: 'pointer'}}>Policies </span></Text>
        </Portion>
          <Portion desktopSpan="12" tabletLandscapeSpan='12' tabletPortraitSpan='12' mobileSpan="14"    marginTop="nano" marginBottom="nano" >
            <Text paddingRight='nano' align="right" style={{color:"#777777"}}>© 2023 Copyright:TrendTonik.com</Text>
          </Portion>
        </Row>
      </FooterStyled>
      {/*
        modified : Ashutosh G
        modified : 12/12/2023
        modification : toggleContributeModal passed to ContributeModal component
      */}
      <FilePreviewer loading={filePreview.loading} visible={!filePreview.forceClosed} onClose={toggleContributeModal}>
         <div>
           <ContributeModal toggleContributeModal={toggleContributeModal}/>
         </div>
      </FilePreviewer>
    </>
  )
}
